// import React, { useState, useEffect } from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Typography,
//   Button,
//   Paper,
//   Snackbar,
//   Alert
// } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// export const ArticlesTable = () => {
//   const [articles, setArticles] = useState([]);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [responseMessage, setResponseMessage] = useState("");
//   const navigate = useNavigate();

//   // Fetch articles on component mount
//   useEffect(() => {
//     fetchArticles();
//   }, []);

//   // Function to fetch articles
//   const fetchArticles = () => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         setArticles(response.data);
//         console.log(response.data);

//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   };

//   // Function to handle article deletion
//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(`${process.env.REACT_APP_URL}/api/cards/deleteArticle/${id}`);
//       setResponseMessage("Article deleted successfully!");
//       setArticles(articles.filter((article) => article._id !== id));
//       setSnackbarOpen(true); // Show Snackbar on success
//     } catch (error) {
//       console.error("Error deleting article:", error);
//       setResponseMessage("Error deleting article!");
//       setSnackbarOpen(true); // Show Snackbar on error
//     }
//   };

//   // Function to handle edit navigation
//   const handleEdit = (slug) => {
//     navigate(`/editarticle/${slug}`);
//   };

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   return (
//     <Box p={4} display="flex">
//       <DrawerHeader />

//       <Snackbar
//         open={snackbarOpen}
//         autoHideDuration={6000} // Auto hide after 6 seconds
//         onClose={handleSnackbarClose}
//         anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of Snackbar
//       >
//         <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
//           {responseMessage}
//         </Alert>
//       </Snackbar>

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={12}
//         sx={{ display: "flex", flexDirection: "column", mt: 10 }}
//       >
//         <Grid
//           item
//           direction="column"
//           sx={{
//             background: "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//             borderRadius: 2,
//             boxShadow: 3,
//             p: 3,
//             border: "2px solid black",
//             position: "relative",
//           }}
//         >
//           <Typography
//             variant="h5"
//             sx={{ mb: 2, color: "white", textAlign: "center" }}
//           >
//             Blog Information
//           </Typography>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={() => navigate("/addarticle")}
//             sx={{
//               position: "absolute",
//               top: 16,
//               right: 16,
//               padding: "6px 16px",
//               fontSize: "14px",
//               borderRadius: "8px",
//               textTransform: "none",
//               border: "2px solid black",
//             }}
//           >
//             Add Articles
//           </Button>

//           <Grid
//             item
//             xs={12}
//             md={10}
//             lg={10}
//             sx={{
//               width: "100%",
//               backgroundColor: "white",
//               borderRadius: 2,
//               mt: 3,
//             }}
//           >
//             <Paper>
//               <table
//                 style={{
//                   width: "100%",
//                   borderCollapse: "collapse",
//                 }}
//               >
//                 <thead>
//                   <tr>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       ID
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Category
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Author
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Header
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Meta Description
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Short Description
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Chosen File
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Edit
//                     </th>
//                     <th style={{ padding: "8px", border: "1px solid #ddd", backgroundColor: "#333", color: "white" }}>
//                       Delete
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {articles.map((article, index) => (
//                     <tr key={article._id}>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{index + 1}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{article.category}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{article.author}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{article.headertext}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{article.metadescription}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>{article.shortdescription}</td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         <img
//                           src={article.imagelink}
//                           alt="Article"
//                           style={{
//                             width: "100px",
//                             height: "auto",
//                             objectFit: "contain",
//                           }}
//                         />
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         <Button
//                           variant="contained"
//                           sx={{
//                             mr: 1,
//                             backgroundColor: "blue",
//                             "&:hover": { backgroundColor: "darkblue" },
//                             color: "white",
//                           }}
//                           onClick={() => handleEdit(article.slug)}
//                         >
//                           Edit
//                         </Button>
//                       </td>
//                       <td style={{ padding: "8px", border: "1px solid #ddd" }}>
//                         <Button
//                           variant="contained"
//                          sx={{  ml: 1,
//                           backgroundColor: "red",
//                           "&:hover": { backgroundColor: "darkred" },
//                           color: "white",}}
//                           onClick={() => handleDelete(article._id)}
//                         >
//                           Delete
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </Paper>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default ArticlesTable;

import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";



export const ArticlesTable = () => {
  const [articles, setArticles] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [articleToDelete, setArticleToDelete] = useState(null);
  const navigate = useNavigate();

  // Fetch articles on component mount
  useEffect(() => {
    fetchArticles();
  }, []);

  // Function to fetch articles
  const fetchArticles = () => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        setArticles(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  };

  // Function to handle article deletion
  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_URL}/api/cards/deleteArticle/${id}`
      );
      setResponseMessage("Article deleted successfully!");
      setArticles(articles.filter((article) => article._id !== id));
      setSnackbarOpen(true); // Show Snackbar on success
    } catch (error) {
      console.error("Error deleting article:", error);
      setResponseMessage("Error deleting article!");
      setSnackbarOpen(true); // Show Snackbar on error
    }
  };

  // Function to handle delete button click
  const handleDeleteClick = (id) => {
    setArticleToDelete(id);
    setOpenConfirmDialog(true);
  };

  // Function to handle confirm delete
  const handleConfirmDelete = () => {
    if (articleToDelete) {
      handleDelete(articleToDelete);
    }
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // Function to handle cancel delete
  const handleCancelDelete = () => {
    setOpenConfirmDialog(false);
    setArticleToDelete(null);
  };

  // Function to handle edit navigation
  const handleEdit = (slug) => {
    navigate(`/editarticle/${slug}`);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box p={4} display="flex">
      <DrawerHeader />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000} // Auto hide after 6 seconds
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }} // Position of Snackbar
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {responseMessage}
        </Alert>
      </Snackbar>

      <Grid
        container
        xs={12}
        md={10}
        lg={12}
        sx={{ display: "flex", flexDirection: "column", mt: 10 }}
      >
        <Grid
          item
          direction="column"
          sx={{
            background:
              "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
            borderRadius: 2,
            boxShadow: 3,
            p: 3,
            border: "2px solid black",
            position: "relative",
          }}
        >
          <Typography
            variant="h5"
            sx={{ mb: 2, color: "white", textAlign: "center" }}
          >
            Blog Information
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/addarticle")}
            sx={{
              position: "absolute",
              top: 16,
              right: 16,
              padding: "6px 16px",
              fontSize: "14px",
              borderRadius: "8px",
              textTransform: "none",
              border: "2px solid black",
            }}
          >
            Add Articles
          </Button>

          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            sx={{
              width: "100%",
              backgroundColor: "white",
              borderRadius: 2,
              mt: 3,
            }}
          >
            <Paper>
              <table
                style={{
                  width: "100%", tableLayout: "fixed", borderCollapse: "collapse" 
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      ID
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Category
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Author
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                        wordBreak: "break-word", // Forces word breaks for long words
                        whiteSpace: "nowrap",    // Keeps text in one line unless overflow occurs
                        overflow: "hidden",      // Hides overflowing text
                        textOverflow: "ellipsis" // Adds ellipses for overflowed text
                      }}
                    >
                      Header
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                        wordBreak: "break-word", // Forces word breaks for long words
                        whiteSpace: "nowrap",    // Keeps text in one line unless overflow occurs
                        overflow: "hidden",      // Hides overflowing text
                        textOverflow: "ellipsis" // Adds ellipses for overflowed text
                      }}
                    >
                      Meta Description
                    </th>
                    <th
                      style={{
                        wordBreak: "break-word", // Forces word breaks for long words
                        whiteSpace: "nowrap",    // Keeps text in one line unless overflow occurs
                        overflow: "hidden",      // Hides overflowing text
                        textOverflow: "ellipsis", // Adds ellipses for overflowed text
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Short Description
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Chosen File
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Edit
                    </th>
                    <th
                      style={{
                        padding: "8px",
                        border: "1px solid #ddd",
                        backgroundColor: "#333",
                        color: "white",
                      }}
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {articles.map((article, index) => (
                    <tr key={article._id}>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {index + 1}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {article.category}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {article.author}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {article.headertext}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {article.metadescription}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        {article.shortdescription}
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        <img
                          src={article.imagelink}
                          alt="Article"
                          style={{
                            width: "100px",
                            height: "auto",
                            objectFit: "contain",
                          }}
                        />
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        <Button
                          variant="contained"
                          sx={{
                            mr: 1,
                            backgroundColor: "blue",
                            "&:hover": { backgroundColor: "darkblue" },
                            color: "white",
                          }}
                          onClick={() => handleEdit(article.slug)}
                        >
                          Edit
                        </Button>
                      </td>
                      <td style={{ padding: "8px", border: "1px solid #ddd" }}>
                        <Button
                          variant="contained"
                          sx={{
                            ml: 1,
                            backgroundColor: "red",
                            "&:hover": { backgroundColor: "darkred" },
                            color: "white",
                          }}
                          onClick={() => handleDeleteClick(article._id)}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog open={openConfirmDialog} onClose={handleCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this article?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ArticlesTable;
