// import React, { useEffect, useState } from "react";
// import {
//   Container,
//   Grid,
//   Box,
//   Card,
//   CardContent,
//   Typography,
//   Avatar,
//   Divider,
//   Button,
//   Pagination,
//   useMediaQuery,
// } from "@mui/material";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import NavBar from "../global/header";
// import Footer from "../global/footer";
// import { useTheme } from "@mui/material/styles";
// import axios from "axios";
// import { useParams, Link } from "react-router-dom";
// import imageCompression from "browser-image-compression";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import {  CardMedia, CardActions, Paper } from "@mui/material";





// const AuthorPage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [articles, setArticles] = useState([]);
//   const [author, setAuthor] = useState(null);
//   const { authorName } = useParams();

//   // Fetch articles by author
//   useEffect(() => {
//     const fetchArticlesByAuthor = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
//         );
//         setArticles(response.data);
//       } catch (error) {
//         console.error("Error fetching articles by author:", error);
//       }
//     };

//     const fetchAuthorDetails = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/authors/getAllAuthors`
//         );
//         const fetchedAuthors = response.data.authors;
//         const currentAuthor = fetchedAuthors.find(
//           (auth) => auth.authorName === authorName
//         );
//         setAuthor(currentAuthor);
//       } catch (error) {
//         console.error("Error fetching author details:", error);
//       }
//     };

//     fetchArticlesByAuthor();
//     fetchAuthorDetails();
//   }, [authorName]);

//   // Handle Image Upload
//   const handleImageUpload = async (event) => {
//     const imageFile = event.target.files[0];

//     // Compression options
//     const options = {
//       maxSizeMB: 1,
//       maxWidthOrHeight: 1920,
//       useWebWorker: true,
//     };

//     try {
//       const compressedFile = await imageCompression(imageFile, options);
//       const formData = new FormData();
//       formData.append("file", compressedFile);

//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/article-images`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       console.log("Image uploaded successfully:", response.data);
//     } catch (error) {
//       console.error("Error uploading image:", error);
//     }
//   };

//   return (
//     <Grid
//       container
//       sx={{
//         backgroundColor: "#FFFFFF",
//         display: "flex",
//         flexDirection: "column",
//         justifyContent: "center",
//         alignItems: "center",
//       }}
//     >
//       <NavBar />

//       <Container maxWidth="lg" sx={{ marginTop: 19 }}>
//         <Grid container spacing={4}>
//           {/* Blog Posts Section */}
//           <Grid item xs={12} md={8}>
//             <Typography
//               variant="h4"
//               sx={{ fontWeight: "bold", marginBottom: 2 }}
//             >
//               Posts by {authorName}
//             </Typography>
//             <Grid container spacing={3}>
//               {articles.map((article) => (
//                 <Grid item xs={12} md={6} key={article._id}>
//                   <Link
//                     to={`/seo/${article.slug}`}
//                     style={{ textDecoration: "none " }}
//                   >
//                     <Grid
//                 item
//                 xs={12}
//                 sx={{
//                   display: "flex",
//                   flexDirection: isMobile ? "row" : "column",
//                   transition: isMobile ? "none" : "transform 0.3s",
//                   "&:hover": {
//                     transform: "scale(1.03)",
//                   },
//                   // width: "100%",
//                   // maxWidth: "100%",
//                   height: isMobile ? "auto" : 380, // Set fixed height for desktop
//                   borderRadius: 2,
//                   overflow: "hidden",
//                   cursor: "pointer",
//                   // mx: "auto",
//                   // border: "2px solid black",
//                 }}
//               >
//                 <CardMedia
//                   component="img"
//                   src={article.imagelink}
//                   alt={article.headertext}
//                   sx={{
//                     height: isMobile ? 120 : 200,
//                     width: isMobile ? "40%" : "100%",
//                     objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                   }}
//                 />
//                 <CardContent
//                   sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "space-between",
//                     padding: 3,
//                     flexGrow: 1, // Ensures equal height distribution
//                     backgroundColor: "#f9f9f9",
//                   }}
//                 >
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       // backgroundColor: "#e0f7fa",
//                       color: theme.palette.warning.main,
//                       borderRadius: 1,
//                       px: 0,
//                       py: 0.5,
//                       fontWeight: "bold",
//                       mb: 1,
//                       display: "inline-block",
//                       textAlign: "left",
//                     }}
//                   >
//                     {article.category}
//                   </Typography>
//                   <Typography
//                     display={"block"}
//                     variant="caption" // Changed to h6 for better visibility on desktop
//                     sx={{
//                       fontWeight: "600",
//                       textAlign: "left",
//                       fontSize: isMobile ? "1rem" : "1rem", // Responsive font size
//                       lineHeight: isMobile ? "1.2" : "1.4", // Adjust line height for readability
//                       marginBottom: isMobile ? "8px" : "14px",
//                       color: "#333",
//                       height: isMobile ? "auto" : "48px", // Fixed height for desktop
//                       // overflow: "hidden",
//                       // textOverflow: "ellipsis", // Add ellipsis for overflow text
//                       // whiteSpace: "nowrap", // Prevent wrapping of the text
//                     }}
//                   >
//                     {article.headertext}
//                   </Typography>
//                 </CardContent>

//                 {!isMobile && (
//                   <CardActions
//                     sx={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       // px: 3,
//                       pb: 2,
//                       backgroundColor: "#fafafa",
//                     }}
//                   >
//                     <Typography
//                       sx={{
//                         color: theme.palette.primary.main,
//                         fontWeight: "bold",
//                       }}
//                     >
//                       Read more »
//                     </Typography>
//                     <Box
//                       sx={{
//                         display: "flex",
//                         alignItems: "center",
//                         color: "#777",
//                       }}
//                     >
//                       <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
//                       {article.clicks}
//                     </Box>
//                   </CardActions>
//                 )}
//               </Grid>
//                   </Link>
//                 </Grid>
//               ))}
//             </Grid>
//             {/* <Box
//               sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
//             >
//               <Pagination count={10} color="primary" />
//             </Box> */}
//           </Grid>

//           {/* Author Details Section */}
//           <Grid item xs={12} md={4}>
//             <Card sx={{ padding: 2, textAlign: "center" }}>
//               <Avatar
//                 src={author?.file || "https://via.placeholder.com/150"} // Ensure this line accesses the correct URL
//                 alt={authorName}
//                 sx={{ width: 150, height: 150, margin: "0 auto" }}
//               />
//               <Typography variant="h5" sx={{ marginTop: 2 }}>
//                 {authorName}
//               </Typography>
//               <Typography
//                 variant="body2"
//                 color="textSecondary"
//                 sx={{ marginTop: 1 }}
//               >
//                 {author?.description || "No description available."}
//               </Typography>
//               <Divider sx={{ marginY: 2 }} />
//               <Box>
//                 <Button
//                   startIcon={<LinkedInIcon />}
//                   href={author?.linkedIn || "https://www.linkedin.com"}
//                   target="_blank"
//                   sx={{ marginRight: 1 }}
//                 >
//                   LinkedIn
//                 </Button>
//                 <Button
//                   startIcon={<TwitterIcon />}
//                   href={author?.twitter || "https://www.twitter.com"}
//                   target="_blank"
//                 >
//                   Twitter
//                 </Button>
//               </Box>
//               {/* Input for uploading an image */}
//               <input
//                 type="file"
//                 accept="image/*"
//                 onChange={handleImageUpload}
//                 style={{ marginTop: 20 }}
//               />
//             </Card>
//           </Grid>
//         </Grid>
//       </Container>
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AuthorPage;


import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Divider,
  Button,
  Pagination,
  useMediaQuery,
  CardMedia,
  CardActions,
} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import NavBar from "../global/header";
import Footer from "../global/footer";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import imageCompression from "browser-image-compression";

const AuthorPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [articles, setArticles] = useState([]);
  const [author, setAuthor] = useState(null);
  const { authorName } = useParams();
  
  const [page, setPage] = useState(1);
  const itemsPerPage = 4; // Number of articles per page

  // Fetch articles by author
  useEffect(() => {
    const fetchArticlesByAuthor = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
        );
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles by author:", error);
      }
    };

    const fetchAuthorDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getAllAuthors`
        );
        const fetchedAuthors = response.data.authors;
        const currentAuthor = fetchedAuthors.find(
          (auth) => auth.authorName === authorName
        );
        setAuthor(currentAuthor);

        // console.log("sasa", currentAuthor);
        
      } catch (error) {
        console.error("Error fetching author details:", error);
      }
    };

    fetchArticlesByAuthor();
    fetchAuthorDetails();
  }, [authorName]);

  // Pagination logic
  const totalPages = Math.ceil(articles.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    // Compression options
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const formData = new FormData();
      formData.append("file", compressedFile);

      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/article-images`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log("Image uploaded successfully:", response.data);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };



  return (
    <Grid
      container
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />

      <Container maxWidth="lg" sx={{ marginTop: 19 }}>
        <Grid container spacing={4}>
          {/* Blog Posts Section */}
          <Grid item xs={12} md={8}>
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", marginBottom: 2 }}
            >
              Posts by {authorName}
            </Typography>
            <Grid container spacing={3}>
              {currentArticles.map((article) => (
                <Grid item xs={12} md={6} key={article._id}>
                  <Link
                    to={`/seo/${article.slug}`}
                    style={{ textDecoration: "none " }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        flexDirection: isMobile ? "row" : "column",
                        transition: isMobile ? "none" : "transform 0.3s",
                        "&:hover": {
                          transform: "scale(1.03)",
                        },
                        height: isMobile ? "auto" : 380,
                        borderRadius: 2,
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                    >
                      <CardMedia
                        component="img"
                        src={article.imagelink}
                        alt={article.headertext}
                        sx={{
                          height: isMobile ? 120 : 200,
                          width: isMobile ? "40%" : "100%",
                          objectFit: isMobile ? "contain" : "fill",
                        }}
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          padding: 3,
                          flexGrow: 1,
                          // backgroundColor: "#f9f9f9",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.warning.main,
                            borderRadius: 1,
                            px: 0,
                            py: 0.5,
                            fontWeight: "bold",
                            mb: 1,
                            display: "inline-block",
                            textAlign: "left",
                          }}
                        >
                          {article.category}
                        </Typography>
                        <Typography
                          display={"block"}
                          variant="caption"
                          sx={{
                            fontWeight: "600",
                            textAlign: "left",
                            fontSize: isMobile ? "1rem" : "1rem",
                            lineHeight: isMobile ? "1.2" : "1.4",
                            marginBottom: isMobile ? "8px" : "14px",
                            color: "#333",
                            height: isMobile ? "auto" : "48px",
                          }}
                        >
                          {article.headertext}
                        </Typography>
                      </CardContent>

                      {!isMobile && (
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            pb: 2,
                            backgroundColor: "#fafafa",
                          }}
                        >
                          <Typography
                            sx={{
                              color: theme.palette.primary.main,
                              fontWeight: "bold",
                            }}
                          >
                            Read more »
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              color: "#777",
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
                            {article.clicks}
                          </Box>
                        </CardActions>
                      )}
                    </Grid>
                  </Link>
                </Grid>
              ))}
            </Grid>
            {/* Pagination */}
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handleChange}
                color="primary"
              />
            </Box>
          </Grid>

          {/* Author Details Section */}
          <Grid item xs={12} md={4}>
            <Card sx={{ padding: 2, textAlign: "center" }}>
              {/* <Avatar
                src={author?.file || "https://via.placeholder.com/150"}
                alt={authorName}
                sx={{
                  width: 150,
                  height: 150,
                  margin: "0 auto",
                  objectFit: "cover", // Ensure the image fills the Avatar correctly
                  borderRadius: "50%", // Make sure the Avatar is circular
                  border: "2px solid #ddd", // Optional: add a border for better appearance
                  marginTop: "15",
                }}
              /> */}

<Avatar
  src={author?.file || "https://via.placeholder.com/150"}
  alt={authorName}
  sx={{
    width: 150,
    height: 190,
    margin: "0 auto",
    objectFit: "cover", // Ensures the image covers the Avatar area
    borderRadius: "0%", // Ensures the avatar remains circular
    border: "2px solid #ddd", // Optional border for appearance
    padding: 0, // Ensure there's no padding inside the Avatar
  }}
/>

              <Typography variant="h5" sx={{ marginTop: 2 }}>
                {authorName}
              </Typography>

              
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginTop: 1 }}
              >
              {/* console.log("asdasaas", description); */}

                {author?.description || "No description available."}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
              <Box>
                <Button
                  startIcon={<LinkedInIcon />}
                  href={author?.linkedIn || "https://www.linkedin.com"}
                  target="_blank"
                  sx={{ marginRight: 1 }}
                >
                  LinkedIn
                </Button>
                <Button
                  startIcon={<TwitterIcon />}
                  href={author?.twitter || "https://www.twitter.com"}
                  target="_blank"
                >
                  Twitter
                </Button>
              </Box>
              {/* <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ marginTop: 20 }}
              /> */}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default AuthorPage;


// import React, { useEffect, useState } from 'react';
// import {
//   Container,
//   Grid,
//   Typography,
//   Avatar,
//   Divider,
//   Button,
//   Pagination,
//   useMediaQuery,
//   CardMedia,
//   CardContent,
//   CardActions,
//   Box,
// } from '@mui/material';
// import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import NavBar from '../global/header';
// import Footer from '../global/footer';
// import { useTheme } from '@mui/material/styles';
// import axios from 'axios';
// import { useParams, Link } from 'react-router-dom';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// const AuthorPage = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [articles, setArticles] = useState([]);
//   const [author, setAuthor] = useState(null);
//   const { authorName } = useParams();

//   const [page, setPage] = useState(1);
//   const itemsPerPage = 4; // Number of articles per page

//   // Fetch articles by author
//   useEffect(() => {
//     const fetchArticlesByAuthor = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbyauthor/${authorName}`
//         );
//         setArticles(response.data);
//       } catch (error) {
//         console.error('Error fetching articles by author:', error);
//       }
//     };

//     const fetchAuthorDetails = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/getAllAuthors`
//         );
//         const fetchedAuthors = response.data.authors;
//         const currentAuthor = fetchedAuthors.find(
//           (auth) => auth.authorName === authorName
//         );
//         setAuthor(currentAuthor);
//       } catch (error) {
//         console.error('Error fetching author details:', error);
//       }
      
//     };
//     console.log("asaa", authorName);

    

//     fetchArticlesByAuthor();
//     fetchAuthorDetails();
//   }, [authorName]);

//   // Pagination logic
//   const totalPages = Math.ceil(articles.length / itemsPerPage);
//   const handleChange = (event, value) => {
//     setPage(value);
//   };

//   const startIndex = (page - 1) * itemsPerPage;
//   const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);

//   return (
//     <Grid
//       container
//       sx={{
//         backgroundColor: '#FFFFFF',
//         display: 'flex',
//         flexDirection: 'column',
//         alignItems: 'center',
//       }}
//     >
//       <NavBar />

//       <Container maxWidth="lg" sx={{ marginTop: 19 }}>
//         <Grid container spacing={4}>
//           {/* Blog Posts Section */}
//           <Grid item xs={12} md={8}>
//             <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
//               Posts by {authorName}
//             </Typography>
//             <Grid container spacing={3}>
//               {currentArticles.map((article) => (
//                 <Grid item xs={12} md={6} key={article._id}>
//                   <Link
//                     to={`/seo/${article.slug}`}
//                     style={{ textDecoration: 'none' }}
//                   >
//                     <Grid
//                       item
//                       xs={12}
//                       sx={{
//                         display: 'flex',
//                         flexDirection: isMobile ? 'row' : 'column',
//                         transition: isMobile ? 'none' : 'transform 0.3s',
//                         '&:hover': {
//                           transform: 'scale(1.03)',
//                         },
//                         height: isMobile ? 'auto' : 380,
//                         borderRadius: 2,
//                         overflow: 'hidden',
//                         cursor: 'pointer',
//                       }}
//                     >
//                       <CardMedia
//                         component="img"
//                         src={article.imagelink}
//                         alt={article.headertext}
//                         sx={{
//                           height: isMobile ? 120 : 200,
//                           width: isMobile ? '40%' : '100%',
//                           objectFit: isMobile ? 'contain' : 'fill',
//                         }}
//                       />
//                       <CardContent
//                         sx={{
//                           display: 'flex',
//                           flexDirection: 'column',
//                           justifyContent: 'space-between',
//                           padding: 3,
//                           flexGrow: 1,
//                           backgroundColor: '#f9f9f9',
//                         }}
//                       >
//                         <Typography
//                           variant="body2"
//                           sx={{
//                             color: theme.palette.warning.main,
//                             borderRadius: 1,
//                             px: 0,
//                             py: 0.5,
//                             fontWeight: 'bold',
//                             mb: 1,
//                             display: 'inline-block',
//                             textAlign: 'left',
//                           }}
//                         >
//                           {article.category}
//                         </Typography>
//                         <Typography
//                           display={'block'}
//                           variant="caption"
//                           sx={{
//                             fontWeight: '600',
//                             textAlign: 'left',
//                             fontSize: isMobile ? '1rem' : '1rem',
//                             lineHeight: isMobile ? '1.2' : '1.4',
//                             marginBottom: isMobile ? '8px' : '14px',
//                             color: '#333',
//                             height: isMobile ? 'auto' : '48px',
//                           }}
//                         >
//                           {article.headertext}
//                         </Typography>
//                       </CardContent>

//                       {!isMobile && (
//                         <CardActions
//                           sx={{
//                             display: 'flex',
//                             justifyContent: 'space-between',
//                             pb: 2,
//                             backgroundColor: '#fafafa',
//                           }}
//                         >
//                           <Typography
//                             sx={{
//                               color: theme.palette.primary.main,
//                               fontWeight: 'bold',
//                             }}
//                           >
//                             Read more »
//                           </Typography>
//                           <Box
//                             sx={{
//                               display: 'flex',
//                               alignItems: 'center',
//                               color: '#777',
//                             }}
//                           >
//                             <RemoveRedEyeIcon sx={{ mr: 0.5, color: '#555' }} />
//                             {article.clicks}
//                           </Box>
//                         </CardActions>
//                       )}
//                     </Grid>
//                   </Link>
//                 </Grid>
//               ))}
//             </Grid>
//             {/* Pagination */}
//             <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
//               <Pagination
//                 count={totalPages}
//                 page={page}
//                 onChange={handleChange}
//                 color="primary"
//               />
//             </Box>
//           </Grid>

//           {/* Author Details Section */}
//           <Grid item xs={12} md={4}>
//             {author ? (
//               <Box sx={{ padding: 2, textAlign: 'center' }}>
//                 <Avatar
//                   src={author.file || 'https://via.placeholder.com/150'}
//                   alt={authorName}
//                   sx={{ width: 150, height: 150, margin: '0 auto' }}
//                 />
//                 <Typography variant="h5" sx={{ marginTop: 2 }}>
//                   {author.authorName}
//                 </Typography>
//                 <Typography
//                   variant="body2"
//                   color="textSecondary"
//                   sx={{ marginTop: 1 }}
//                 >
//                   {author.description || 'No description available.'}
//                 </Typography>
//                 <Divider sx={{ marginY: 2 }} />
//                 <Box>
//                   {author.linkedIn && (
//                     <Button
//                       startIcon={<LinkedInIcon />}
//                       href={author.linkedIn}
//                       target="_blank"
//                       sx={{ marginRight: 1 }}
//                     >
//                       LinkedIn
//                     </Button>
//                   )}
//                   {author.twitter && (
//                     <Button
//                       startIcon={<TwitterIcon />}
//                       href={author.twitter}
//                       target="_blank"
//                     >
//                       Twitter
//                     </Button>
//                   )}
//                 </Box>
//               </Box>
//             ) : (
//               <Typography variant="h6">Author not found.</Typography>
//             )}
//           </Grid>
//         </Grid>
//       </Container>
//       <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
//         <Footer />
//       </Grid>
//     </Grid>
//   );
// };

// export default AuthorPage;
