
// import { useParams } from "react-router-dom";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { Grid, Typography, Card, CardContent, CardMedia, CardActions, Box } from "@mui/material";
// import NavBar from "../global/header";
// import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";
// import { Link } from "react-router-dom";

// const CategoryPage = () => {
//   const { category } = useParams();
//   const [articles, setArticles] = useState([]);
//   console.log("123456" , category);
  
//   useEffect(() => {
//     const fetchArticlesByCategory = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbycategory/${category}`
//         );
//         setArticles(response.data);
       
//         console.log("789456" , response.data)
        
//       } catch (error) {
//         console.error("Error fetching articles by category", error);
//       }
//     };

//     fetchArticlesByCategory();
//   }, [category]);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Grid container direction="column" alignItems="center" spacing={2} maxWidth="lg">
//       <Grid item xs={12}>
//         <NavBar />

//         <Grid container mt={isMobile ? 10 : 15} justifyContent="center" spacing={3} paddingLeft={isMobile ? 5:12}>
//         {articles.map((article, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Link
//               to={`/seo/${article.slug}`}
//               style={{
//                 textDecoration: "none",
//                 color: theme.palette.primary.main,
//                 fontWeight: "bold",
//               }}
//             >
        


// <Grid item xs={12}
//   sx={{
//     display: "flex",
//     flexDirection: isMobile ? "row" : "column",
//     transition: isMobile ? "none" : "transform 0.3s",
//     "&:hover": {
//       transform: "scale(1.03)",
//     },
//     // width: "100%",
//     // maxWidth: "100%",
//     height: isMobile ? "auto" : 380, // Set fixed height for desktop
//     borderRadius: 2,
//     overflow: "hidden",
//     cursor: "pointer",
//     // mx: "auto",
//     // border: "2px solid black",
//   }}
// >
//   <CardMedia
//     component="img"
//     src={article.imagelink}
//     alt={article.headertext}
//     sx={{
//       height: isMobile ? 120 : 200,
//        width: isMobile ? "40%" : "100%",
//                    objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area

//     }}
//   />
//   <CardContent
//     sx={{
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "space-between",
//       padding: 3,
//       flexGrow: 1, // Ensures equal height distribution
//       backgroundColor: "#f9f9f9",
//     }}
//   >
//     <Typography
//       variant="body2"
//       sx={{
//         // backgroundColor: "#e0f7fa",
//         color: theme.palette.warning.main,
//         borderRadius: 1,
//         px: 0,
//         py: 0.5,
//         fontWeight: "bold",
//         mb: 1,
//         display: "inline-block",
//         textAlign:"left"
//       }}
//     >
//       {article.category}
//     </Typography>
//     <Typography
//     display={"block"}
//       variant="caption" // Changed to h6 for better visibility on desktop
//       sx={{
//         fontWeight: "600",
//         textAlign: "left",
//         fontSize: isMobile ? "1rem" : "1rem", // Responsive font size
//         lineHeight: isMobile ? "1.2" : "1.4", // Adjust line height for readability
//         marginBottom: isMobile ? "8px" : "14px",
//         color: "#333",
//         height: isMobile ? "auto" : "48px", // Fixed height for desktop
//         // overflow: "hidden",
//         // textOverflow: "ellipsis", // Add ellipsis for overflow text
//         // whiteSpace: "nowrap", // Prevent wrapping of the text
//       }}
//     >
//       {article.headertext}
//     </Typography>
//   </CardContent>



//   {!isMobile && (
//     <CardActions
//       sx={{
//         display: "flex",
//         justifyContent: "space-between",
//         // px: 3,
//         pb: 2,
//         backgroundColor: "#fafafa",
//       }}
//     >
//       <Typography
//         sx={{
//           color: theme.palette.primary.main,
//           fontWeight: "bold",
//         }}
//       >
//         Read more »
//       </Typography>
//       <Box sx={{ display: "flex", alignItems: "center", color: "#777" }}>
//         <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
//         {article.clicks}
//       </Box>
//     </CardActions>
//   )}
// </Grid>

//             </Link>
//           </Grid>
//         ))}
//       </Grid>
      
//     </Grid>
//     </Grid>
    

//   );
// };

// export default CategoryPage;


import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Box,
  Pagination,
} from "@mui/material";
import NavBar from "../global/header";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

const CategoryPage = () => {
  const { category } = useParams();
  const [articles, setArticles] = useState([]);

  // Pagination state
  const [page, setPage] = useState(1);
  const itemsPerPage = 6; // Number of articles per page

  useEffect(() => {
    const fetchArticlesByCategory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/cards/getarticlesbycategory/${category}`
        );
        setArticles(response.data);
      } catch (error) {
        console.error("Error fetching articles by category", error);
      }
    };

    fetchArticlesByCategory();
  }, [category]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Pagination logic
  const totalPages = Math.ceil(articles.length / itemsPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const currentArticles = articles.slice(startIndex, startIndex + itemsPerPage);

  return (
     <Grid
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
     
        <NavBar />
        <Grid container xs={12} md={10} lg={10}  mt={isMobile ? 10 : 18} justifyContent="center" spacing={3} >
          {currentArticles.map((article, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Link
                to={`/seo/${article.slug}`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "row" : "column",
                    transition: isMobile ? "none" : "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.03)",
                    },
                    height: isMobile ? "auto" : 380,
                    borderRadius: 2,
                    overflow: "hidden",
                    cursor: "pointer",
                    // border:"2px solid black"
                  }}
                >
                  <CardMedia
                    component="img"
                    src={article.imagelink}
                    alt={article.headertext}
                    sx={{
                      height: isMobile ? 120 : 200,
                      width: isMobile ? "40%" : "100%",
                      objectFit: isMobile ? "contain" : "fill",
                    }}
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      padding: 3,
                      flexGrow: 1,
                      backgroundColor: "#f9f9f9",
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: theme.palette.warning.main,
                        borderRadius: 1,
                        px: 0,
                        py: 0.5,
                        fontWeight: "bold",
                        mb: 1,
                        display: "inline-block",
                        textAlign: "left",
                      }}
                    >
                      {article.category}
                    </Typography>
                    <Typography
                      display={"block"}
                      variant="caption"
                      sx={{
                        fontWeight: "600",
                        textAlign: "left",
                        fontSize: isMobile ? "1rem" : "1rem",
                        lineHeight: isMobile ? "1.2" : "1.4",
                        marginBottom: isMobile ? "8px" : "14px",
                        color: "#333",
                        height: isMobile ? "auto" : "48px",
                      }}
                    >
                      {article.headertext}
                    </Typography>
                  </CardContent>

                  {!isMobile && (
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 2,
                        backgroundColor: "#fafafa",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.primary.main,
                          fontWeight: "bold",
                        }}
                      >
                        Read more »
                      </Typography>
                      <Box sx={{ display: "flex", alignItems: "center", color: "#777" }}>
                        <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
                        {article.clicks}
                      </Box>
                    </CardActions>
                  )}
                </Grid>
              </Link>
            </Grid>
          ))}
        </Grid>

        {/* Pagination Component */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Grid>
      </Grid>
   
  );
};

export default CategoryPage;
