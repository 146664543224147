

// import React, { useState, useEffect } from "react";
// import { Grid, Box, Typography, Button, Paper } from "@mui/material";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import axios from "axios";
// import Alert from "@mui/material/Alert";

// export const SubscriberTable = () => {
//   const [Subscriber, setSubscribers] = useState([]);
//   const [darkMode, setDarkMode] = useState(false);
//   const [response, setResponse] = useState("");

//   useEffect(() => {
//     const fetchSubscriber = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/getallsubscriber`
//         );

//         setSubscribers(response.data.subscribers.reverse());
//       } catch (error) {
//         console.error("Error fetching subscribers:", error);
//       }
//     };

//     fetchSubscriber();
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_URL}/api/deleteSubscriber/${id}`
//       );
//       setResponse("Subscriber deleted successfully!");

//       setSubscribers(Subscriber.filter((subscriber) => subscriber._id !== id));

//       setTimeout(() => {
//         setResponse("");
//       }, 2000);
//     } catch (error) {
//       console.error("Error deleting subscriber:", error);
//       setResponse("Error deleting subscriber!");

//       setTimeout(() => {
//         setResponse("");
//       }, 4000);
//     }
//   };

//   return (
//     <Box p={4} display="flex" justifyContent="center">
//       <DrawerHeader />

//       <Grid
//         container
//         xs={12}
//         md={10}
//         lg={12}
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           mt: 10,
//           alignItems: "center",
//         }}
//       >
//         <Grid
//           container
//           xs={12}
//           md={10}
//           lg={12}
//           direction="column"
//           sx={{
//             background: darkMode
//               ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
//               : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
//             borderRadius: 2,
//             boxShadow: 3,
//             p: 3,
//             border: "2px solid black",
//             position: "relative",
//             overflow: "hidden", // Ensure content stays within the borders
//           }}
//         >
//           {response && (
//             <Alert
//               severity="success"
//               color="warning"
//               sx={{
//                 mb: 2,
//                 width: "300px",
//                 mx: "auto",
//                 position: "absolute",
//                 top: 20,
//                 left: 40,
//                 right: 0,
//                 transition: "opacity 0.5s ease-in-out",
//               }}
//             >
//               <Typography variant="body1" color="secondary" mt={2}>
//                 {response}
//               </Typography>
//             </Alert>
//           )}
//           <Typography
//             variant="h5"
//             sx={{ mb: 2, color: "white", textAlign: "center" }}
//           >
//             Blog Information
//           </Typography>

//           <Grid
//             sx={{
//               width: "98%",
//               backgroundColor: "white",
//               borderRadius: 2,
//               mt: 3,
//               p: 2,
//               overflowX: "auto", // Prevent horizontal overflow
//               border: "1px solid #ddd",
//             }}
//           >
//             <table
//               style={{
//                 width: "100%",
//                 borderCollapse: "collapse",
//                 border: "1px solid #ddd",
//               }}
//             >
//               <thead>
//                 <tr>
//                   <th
//                     style={{
//                       padding: "8px",
//                       border: "1px solid #ddd",
//                       backgroundColor: "#333",
//                       color: "white",
//                     }}
//                   >
//                     ID
//                   </th>
//                   <th
//                     style={{
//                       padding: "8px",
//                       border: "1px solid #ddd",
//                       backgroundColor: "#333",
//                       color: "white",
//                     }}
//                   >
//                     NAME
//                   </th>
//                   <th
//                     style={{
//                       padding: "8px",
//                       border: "1px solid #ddd",
//                       backgroundColor: "#333",
//                       color: "white",
//                     }}
//                   >
//                     EMAIL
//                   </th>
//                   <th
//                     style={{
//                       padding: "8px",
//                       border: "1px solid #ddd",
//                       backgroundColor: "#333",
//                       color: "white",
//                     }}
//                   >
//                     ACTIONS
//                   </th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {Subscriber?.map((subscriber, index) => (
//                   <tr key={subscriber._id}>
//                     <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                       {index + 1}
//                     </td>
//                     <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                       {subscriber.name}
//                     </td>
//                     <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                       {subscriber.email}
//                     </td>
//                     <td style={{ border: "1px solid #ddd", padding: "6px" }}>
//                       <Button
//                         variant="contained"
//                         color="error"
//                         onClick={() => handleDelete(subscriber._id)}
//                       >
//                         Delete
//                       </Button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </Grid>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// export default SubscriberTable;


import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button, Paper, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from "@mui/material";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import axios from "axios";

export const SubscriberTable = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [darkMode, setDarkMode] = useState(false);
  const [response, setResponse] = useState("");
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedSubscriberId, setSelectedSubscriberId] = useState(null);

  useEffect(() => {
    const fetchSubscribers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/getallsubscriber`
        );
        setSubscribers(response.data.subscribers.reverse());
      } catch (error) {
        console.error("Error fetching subscribers:", error);
      }
    };




    fetchSubscribers();
  }, []);

  const handleDelete = async () => {
    if (selectedSubscriberId) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_URL}/api/deleteSubscriber/${selectedSubscriberId}`
        );
        setResponse("Subscriber deleted successfully!");
        setSubscribers(subscribers.filter(subscriber => subscriber._id !== selectedSubscriberId));
        setSelectedSubscriberId(null);
      } catch (error) {
        console.error("Error deleting subscriber:", error);
        setResponse("Error deleting subscriber!");
      } finally {
        setConfirmDialogOpen(false);
        setTimeout(() => {
          setResponse("");
        }, 4000);
      }
    }
  };

  const handleConfirmDelete = (id) => {
    setSelectedSubscriberId(id);
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setSelectedSubscriberId(null);
  };

  return (
    <Box p={4} display="flex" justifyContent="center">
      <DrawerHeader />

      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 10,
          alignItems: "center",
        }}
      >
        <Grid
          container
          xs={12}
          md={10}
          lg={12}
          direction="column"
          sx={{
            background: darkMode
              ? "linear-gradient(to left, #0A1828, #1a237e, #178582, #BFA181)"
              : "linear-gradient(to left, #833ab4, #0A1828, #178582, #BFA181)",
            borderRadius: 2,
            boxShadow: 3,
            p: 3,
            border: "2px solid black",
            position: "relative",
            overflow: "hidden", // Ensure content stays within the borders
          }}
        >
          {response && (
            <Alert
              severity="success"
              color="warning"
              sx={{
                mb: 2,
                width: "300px",
                mx: "auto",
                position: "absolute",
                top: 20,
                left: 40,
                right: 0,
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              <Typography variant="body1" color="secondary" mt={2}>
                {response}
              </Typography>
            </Alert>
          )}
          <Typography
            variant="h5"
            sx={{ mb: 2, color: "white", textAlign: "center" }}
          >
            Subscriber Information
          </Typography>

          <Grid
            sx={{
              width: "98%",
              backgroundColor: "white",
              borderRadius: 2,
              mt: 5,
              p: 2,
              overflowX: "auto", // Prevent horizontal overflow
              border: "1px solid #ddd",
            }}
          >
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
                border: "1px solid #ddd",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd",
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd",
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    NAME
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd",
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    EMAIL
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      border: "1px solid #ddd",
                      backgroundColor: "#333",
                      color: "white",
                    }}
                  >
                    ACTIONS
                  </th>
                </tr>
              </thead>
              <tbody>

              
                {subscribers.map((subscriber, index) => (
                  <tr key={subscriber._id}>
                    <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                      {index + 1}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                      {subscriber.name}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                      {subscriber.email}
                    </td>
                    <td style={{ border: "1px solid #ddd", padding: "6px" }}>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleConfirmDelete(subscriber._id)}
                        sx={{
                          ml: 1,
                          backgroundColor: "red",
                          "&:hover": { backgroundColor: "darkred" },
                          color: "white",
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this subscriber?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>Cancel</Button>
          <Button onClick={handleDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SubscriberTable;
