import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NavBar from "./header";
import background from "../main/img/mark.jpg";
import { Box, Button, CardMedia, CardActions, Paper } from "@mui/material";
import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
import ContentMarketingImg from "../main/img/content-marketing.jpg";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dropImg from "../main/img/drop.jpg";
// import EmailMarketingImg from '../main/img/email-marketing.jpg';
import seoImg from "../main/img/seo.jpg"; // Add this image
import DigitalImg from "../main/img/Digital.png";
// import InstallationCard from './InstallationCard';
import { styled } from "@mui/material";
import { blue } from "@mui/material/colors";
import { ArrowForward } from "@mui/icons-material";
import { borderRadius, color, textAlign } from "@mui/system";
import img1 from "../main/img/img1.png";
import digi from "../main/img/digi.jpg";
import email from "../main/img/email.png";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import robo from "../main/img/robo.gif";
import abc from "../main/img/abc.webp";
import dolar from "../main/img/dolar.webp";
import tightropeImage from "../main/img/tightrope.png";
import unnamed9Image from "../main/img/unnamed9.png";
import bakingcookiesImage from "../main/img/bakingcookies.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "./footer";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { MetadescriptionSetterForPage } from "../Meta Description/meta_description";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const CustomCard = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  backgroundColor: "#EBF5FB ", // Set background color to transparent
  boxShadow: theme.shadows[3], // Adding a subtle shadow
  transition: "transform 0.3s ease-in-out", // Smooth transform on hover
  "&:hover": {
    transform: "scale(1.05)", // Scale up on hover for attraction
  },
}));

const categories = [
  "Technology",
  "Leadership",
  "Talent",
  "Culture",
  "Explainers",
];

const articles = [
  {
    category: "DE&I",
    title:
      "‘A deeply unequal act:’ HR execs alarmed by SHRM’s decision to drop the ‘E’ from ‘DE&I’",
  },
  {
    category: "Culture",
    title: "What Biden's age discussion means for workplace ageism",
  },
  {
    category: "Technology",
    title:
      "HR leaders with AI skills in high demand and getting major pay raises",
  },
  {
    category: "Technology",
    title: "WTF is Shadow AI?",
  },
  {
    category: "Leadership",
    title: "How Mastercard is leveraging AI in talent acquisition",
  },
];

const theme = createTheme({
  typography: {
    fontFamily: "Lora, Arial, sans-serif",
  },
  palette: {
    primary: {
      main: "#1976d2", // Adjust to match the blue color in the image
    },
  },
});

function Linkage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid sx={{ display: "flex", alignItems: "center", mb: 6 }}>
        <Grid item xs={12} md={4} lg={12}>
          {!isMobile && (
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                flex: 1,

                fontWeight: "bold",
                mt: { xs: 2, md: 0 },
                fontStyle: "italic",
              }}
            />
          )}
        </Grid>

        <Grid item xs={12} md={4} lg={6}>
          <Typography
            sx={{
              textAlign: "center",
              color: "black",
              fontSize: { xs: "0.75rem", sm: "0.95rem" },
              fontFamily: "Graphik",
            }}
          >
            {/* <Link href="xyzImg" sx={{ color: "red" }}>
              Subscribe to Marketing Inc+
            </Link>{" "} */}
            <bold>Marketing Inc</bold>
          </Typography>
        </Grid>

        <Grid item xs={12} md={4} lg={12}>
          {!isMobile && (
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                flex: 1,

                fontWeight: "bold",
                mt: { xs: 2, md: 0 },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function ReadCard() {
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const { slug } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile view

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data.slice(1, 7);
        setArticle(articlesArray);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };

  return (
    //     <Grid container spacing={2}>
    //       <Grid item xs={12}>
    //         <Grid
    //           container
    //           justifyContent="center"
    //           alignItems="center"
    //           spacing={3}
    //           sx={{ textAlign: "left", mt: 1 }}
    //         >
    //           {article.map((article, index) => (
    //             <Grid
    //               item
    //               xs={12}
    //               sm={6}
    //               md={4}
    //               lg={4}
    //               key={index}
    //               sx={{ cursor: "pointer" }}
    //               onClick={() => handleClick(article.slug)}
    //             >
    //               <Grid
    //                 sx={{
    //                   height: isMobile ? "auto" :"100%", // Fixed height for desktop view
    //                   width: isMobile ? "auto" : "auto",
    //                   display: "flex",
    //                   flexDirection: isMobile ? "row" : "column", // Row layout for mobile, column for desktop
    //                   justifyContent: "space-around",
    //                   transition: isMobile ? "none":"transform 0.3s",
    //                   "&:hover": {
    //                     transform: !isMobile && "scale(1.03)", // Only apply hover effect on desktop
    //                   },
    //                 }}
    //              >
    // {/* sm={10}
    //                 sx={{
    //                   display: "flex",
    //                   flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
    //                   justifyContent: "space-around",
    //                   height: isMobile ? "auto" : 400, // Fixed height for desktop view
    //                   width: isMobile ? "auto" : "auto",
    //                   boxShadow: isMobile ? 0 : 1,
    //                   padding: isMobile ? 0 : 1, // Adjust padding for mobile
    //                   transition: isMobile ? "none" : "transform 0.3s",
    //                   "&:hover": {
    //                     transform: "scale(1.03)",
    //                   },
    //                 }} */}

    //                 <CardMedia
    //                   component="img"
    //                   image={article.imagelink}
    //                   alt={article.headertext}
    //                   sx={{
    //                     height: isMobile ? 110 : 180, // Adjust height for mobile view
    //                     width: isMobile ? "40%" : "100%", // Make the image take 40% width in mobile view
    //                     objectFit: isMobile ? "contain" : "fill", // Ensure images cover the area
    //                   }}
    //                 />
    //                 <CardContent
    //                   sx={{
    //                     width: isMobile ? "60%" : "100%", // Text content takes 60% of the width in mobile
    //                     display: "flex",
    //                     flexDirection: "column",
    //                     justifyContent: "space-between",
    //                   }}
    //                 >
    //                   <Typography
    //                     gutterBottom
    //                     variant="body1"
    //                     sx={{
    //                       fontSize: "1.1rem",
    //                       fontWeight: 600,
    //                       overflow: "hidden",
    //                       textOverflow: "ellipsis",
    //                       // whiteSpace: "nowrap",
    //                     }}
    //                   >
    //                     {article.headertext}
    //                   </Typography>

    //                   {/* Hide the "Read more" and eye icon in mobile view */}
    //                   {!isMobile && (
    //                     <Grid
    //                       container
    //                       alignItems="center"
    //                       justifyContent="space-between"
    //                     >
    //                       <Typography
    //                         variant="body2"
    //                         sx={{
    //                           display: "flex",
    //                           alignItems: "center",
    //                           color: "#555",
    //                         }}
    //                       >
    //                         <RemoveRedEyeIcon
    //                           sx={{
    //                             marginRight: 0.5,
    //                             color: "grey.700",
    //                             opacity: 0.7,
    //                           }}
    //                         />
    //                         {article.clicks}
    //                       </Typography>
    //                       <Typography
    //                         variant="body2"
    //                         sx={{
    //                           textDecoration: "none",
    //                           color: "primary.main",
    //                           "&:hover": { textDecoration: "underline" },
    //                         }}
    //                       >
    //                         Read more »
    //                       </Typography>
    //                     </Grid>
    //                   )}
    //                 </CardContent>
    //               </Grid>
    //             </Grid>
    //           ))}
    //         </Grid>
    //       </Grid>
    //     </Grid>

    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={3}
          sx={{ textAlign: "left", mt: 1 }}
        >
          {article.map((article, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              key={index}
              sx={{ cursor: "pointer" }}
              onClick={() => handleClick(article.slug)}
            >
              <Grid
                sx={{
                  height: isMobile ? "auto" : "400px", // Set a consistent height for all cards in desktop view
                  width: isMobile ? "auto" : "100%", // Consistent width for desktop
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column", // Row layout for mobile, column for desktop
                  justifyContent: "space-between",
                  transition: isMobile ? "none" : "transform 0.3s",
                  "&:hover": {
                    transform: !isMobile && "scale(1.03)", // Only apply hover effect on desktop
                  },
                }}
              >
                <CardMedia
                  component="img"
                  image={article.imagelink}
                  alt={article.headertext}
                  sx={{
                    height: isMobile ? 110 : "60%", // Consistent image height in desktop
                    width: isMobile ? "40%" : "100%", // Full width in desktop, 40% in mobile
                    objectFit: isMobile ? "contain" : "fill", // Fill in desktop, contain in mobile
                  }}
                />
                <CardContent
                  sx={{
                    width: isMobile ? "60%" : "auto",
                    height: "40%", // Full width for text content in desktop
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start",
                    // border:'2px solid black'
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="body1"
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: 600,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: isMobile ? "normal" : "normal", // Adjust text behavior in mobile and desktop
                    }}
                  >
                    {article.headertext}
                  </Typography>

                  {/* Hide the "Read more" and eye icon in mobile view */}
                  {!isMobile && (
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ mt: 2 }} // Ensure proper spacing at the bottom
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#555",
                        }}
                      >
                        <RemoveRedEyeIcon
                          sx={{
                            marginRight: 0.5,
                            color: "grey.700",
                            opacity: 0.7,
                          }}
                        />
                        {article.clicks}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          textDecoration: "none",
                          color: "primary.main",
                          "&:hover": { textDecoration: "underline" },
                        }}
                      >
                        Read more »
                      </Typography>
                    </Grid>
                  )}
                </CardContent>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

function BlogPosts() {
  const blogs = [
    {
      category: "AGENCY CULTURE",
      title:
        "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
      date: "September 16, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "September 15, 2024",
    },
    {
      category: "SPONSORED",
      title:
        "Q&A: How advertisers are optimizing CTV ad spend and measuring success",
      date: "September 19, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "September 15, 2024",
    },
    {
      category: "THE CREATOR ECONOMY",
      title: "Why longer videos are becoming more commonplace on YouTube",
      date: "September 19, 2024",
    },
    {
      category: "SPONSORED",
      title:
        "From Killabears to the Stanley Cup Playoffs, how AR drove deep audience engagement",
      date: "September 17, 2024",
    },
    {
      category: "AGENCY CULTURE",
      title:
        "Clients want agencies to deliver industry expertise, agility and empathy, new research finds",
      date: "September 16, 2024",
    },
    {
      category: "THE CONFESSIONS",
      title:
        "Confessions of a DTC investor on the difficulty of dealing with the ‘increasingly common’ founder-influencer",
      date: "September 15, 2024",
    },
  ];

  return (
    <Grid container spacing={2} sx={{ textAlign: "left" }}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography
          variant="h6"
          component="div"
          textAlign="left"
          sx={{
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "800",
            marginBottom: 3,
          }}
        >
          LATEST NEWS
        </Typography>
      </Grid>
      {blogs.map((blog, index) => (
        <Grid item xs={12} key={index}>
          <Typography
            variant="overline"
            display="block"
            gutterBottom
            sx={{
              color: "rgba(0, 0, 0, 0.54)",
              fontSize: "0.55rem",
              marginBottom: 1,
            }}
          >
            {blog.category}
          </Typography>
          <Typography
            variant="h8"
            component="div"
            gutterBottom
            sx={{ fontWeight: "bold", marginBottom: 1 }}
          >
            {blog.title}
          </Typography>
          <Typography variant="caption" color="text.secondary" gutterBottom>
            {blog.date}
          </Typography>
          {index < blogs.length - 1 && (
            <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

// function ImageSection1() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();
//   const { category } = useParams();

//   useEffect(() => {
//     axios
//      `${process.env.REACT_APP_URL}/api/cards/getarticlesbycategory/${category}`
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// const ImageSection1 = (categories) => {

//   const category = categories.category

//   const [articles, setArticles] = useState([]);

//   useEffect(() => {
//     const fetchArticlesByCategory = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_URL}/api/cards/getarticlesbycategory/${category}`
//         );
//         console.log('====================================AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA');
//         console.log(response.data);
//         console.log('====================================');
//         const topThreeArticles = response.data.slice(0, 3);

//         setArticles(topThreeArticles);

//       } catch (error) {
//         console.error("Error fetching articles by category", error);
//       }
//     };

//     fetchArticlesByCategory();
//   }, [category]);

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Grid
//       container
//       direction="column"
//       alignItems="center"
//       spacing={2}
//       maxWidth="lg"
//     >
//       <Grid item xs={12}>
//         <NavBar />

//         <Grid
//           container
//           spacing={2}
//           mt={isMobile ? 10 : 20}
//           justifyContent="center"
//         >
//           {articles.map((article, index) => (
//             <Grid
//               item
//               xs={12}
//               sm={6}
//               md={4}
//               key={index}
//               container
//               justifyContent="center"
//             >
//               <Link
//                 to={`/seo/${article.slug}`}
//                 style={{
//                   textDecoration: "none",
//                   color: theme.palette.primary.main,
//                   fontWeight: "bold",
//                   "&:hover": {
//                     textDecoration: "underline",
//                   },
//                 }}
//               >
//                 <Card
//                   sx={{
//                     display: "flex",
//                     flexDirection: isMobile ? "row" : "column",
//                     height: isMobile ? "auto" : 400,
//                     transition: "transform 0.3s",
//                     "&:hover": {
//                       transform: "scale(1.03)",
//                     },
//                     width: "100%", // Ensure the card takes full width of its container
//                     maxWidth: 345, // Set a maximum width for the card
//                     mx: "auto", // Center the card horizontally
//                     cursor: "pointer",
//                 border:"2px solid black"

//                   }}
//                 >
//                   <CardMedia
//                     component="img"
//                     src={article.imagelink}
//                     alt={article.headertext}
//                     sx={{
//                       height: isMobile ? 100 : 200,
//                       width: isMobile ? "40%" : "100%",
//                       objectFit: isMobile ? "contain" : "cover",
//                     }}
//                   />
//                   <CardContent
//                     sx={{
//                       display: "flex",
//                       flexDirection: "column",
//                       justifyContent: "space-between",
//                       width: "100%",
//                       padding: 2,
//                     }}
//                   >
//                     <Typography
//                       variant="body2"
//                       color="red"
//                       sx={{
//                         borderRadius: 1,
//                         px: 1,
//                         py: 0.5,
//                         fontWeight: "bold",
//                         mb: 1,
//                         textAlign: "left",
//                       }}
//                     >
//                       {article.category}
//                     </Typography>
//                     <Typography
//                       variant="h6"
//                       sx={{
//                         fontWeight: "bold",
//                         textAlign: "left",
//                         fontSize: "1.1rem",
//                       }}
//                     >
//                       {article.headertext}
//                     </Typography>
//                   </CardContent>

//                   {!isMobile && (
//                     <CardActions
//                       sx={{
//                         display: "flex",
//                         justifyContent: "space-between",
//                         px: 2,
//                         pb: 1,
//                       }}
//                     >
//                       <Typography sx={{ color: "blue" }}>
//                         Read more »
//                       </Typography>

//                       <Typography
//                         variant="body2"
//                         sx={{
//                           display: "flex",
//                           alignItems: "center",
//                           color: "grey.800",
//                         }}
//                       >
//                         <RemoveRedEyeIcon
//                           sx={{
//                             mr: 0.5,
//                             color: "grey.700",
//                             fontSize: "1.1rem",
//                           }}
//                         />
//                         {article.clicks}
//                       </Typography>
//                     </CardActions>
//                   )}
//                 </Card>
//               </Link>
//             </Grid>
//           ))}
//         </Grid>
//       </Grid>
//     </Grid>
//   );
// };

const ImageSection1 = (categories) => {
  const category = categories.category;
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticlesByCategory = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL}/api/cards/getarticlesbycategory/${category}`
        );
        const topThreeArticles = response.data.slice(0, 3);
        setArticles(topThreeArticles);
      } catch (error) {
        console.error("Error fetching articles by category", error);
      }
    };
    fetchArticlesByCategory();
  }, [category]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    // <Grid container direction="column" alignItems="center" spacing={2} maxWidth="lg">
    //   <Grid item xs={12}>
    //     <Grid container  mt={isMobile ? 10 : 2} justifyContent="center">
    //       {articles.map((article, index) => (
    //         <Grid item xs={12} sm={6} md={4} key={index} container justifyContent="center">
    //           <Link
    //             to={`/seo/${article.slug}`}
    //             style={{
    //               textDecoration: "none",
    //               color: theme.palette.primary.main,
    //               fontWeight: "bold",
    //             }}
    //           >
    //             <Grid
    //               sx={{
    //                 display: "flex",
    //                 flexDirection: isMobile ? "row" : "column",
    //                 transition: isMobile ? "none" : "transform 0.3s",
    //                 "&:hover": {
    //                   transform: "scale(1.03)",
    //                 },
    //                 width: "100%",
    //                 maxWidth: "100%",
    //                 borderRadius: 2,
    //                 // boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    //                 overflow: "hidden",
    //                 cursor: "pointer",
    //                 mx: "auto",
    //                 border:"2px solid black"
    //               }}
    //             >
    //               <CardMedia
    //                 component="img"
    //                 src={article.imagelink}
    //                 alt={article.headertext}
    //                 sx={{
    //                   height: isMobile ? 120 : 200,
    //                   width: isMobile ? "40%" : "100%",
    //                   objectFit: "cover",
    //                 }}
    //               />
    //               <CardContent
    //                 sx={{
    //                   display: "flex",
    //                   flexDirection: "column",
    //                   justifyContent: "space-between",
    //                   padding: 3,
    //                   backgroundColor: "#f9f9f9",
    //                 }}
    //               >
    //                 <Typography
    //                   variant="body2"
    //                   sx={{
    //                     backgroundColor: "#e0f7fa",
    //                     color: theme.palette.primary.main,
    //                     borderRadius: 1,
    //                     px: 2,
    //                     py: 0.5,
    //                     fontWeight: "bold",
    //                     mb: 1,
    //                     display: "inline-block",
    //                   }}
    //                 >
    //                   {article.category}
    //                 </Typography>
    //                 <Typography
    //                   variant="h6"
    //                   sx={{
    //                     fontWeight: "bold",
    //                     textAlign: "left",
    //                     fontSize: "1.2rem",
    //                     mb: 1.5,
    //                     color: "#333",
    //                   }}
    //                 >
    //                   {article.headertext}
    //                 </Typography>
    //               </CardContent>

    //               {!isMobile && (
    //                 <CardActions
    //                   sx={{
    //                     display: "flex",
    //                     justifyContent: "space-between",
    //                     px: 3,
    //                     pb: 2,
    //                     backgroundColor: "#fafafa",
    //                   }}
    //                 >
    //                   <Typography
    //                     sx={{
    //                       color: theme.palette.primary.main,
    //                       fontWeight: "bold",
    //                     }}
    //                   >
    //                     Read more »
    //                   </Typography>
    //                   <Box sx={{ display: "flex", alignItems: "center", color: "#777" }}>
    //                     <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
    //                     {article.clicks}
    //                   </Box>
    //                 </CardActions>
    //               )}
    //             </Grid>
    //           </Link>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Grid>
    // </Grid>
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="lg"
    >
      {/* <Grid item xs={12}> */}
      <Grid
        container
        mt={isMobile ? 10 : 2}
        justifyContent="center"
        spacing={3}
      >
        {articles.map((article, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Link
              to={`/seo/${article.slug}`}
              style={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                fontWeight: "bold",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                  transition: isMobile ? "none" : "transform 0.3s",
                  "&:hover": {
                    transform: "scale(1.03)",
                  },
                  // width: "100%",
                  // maxWidth: "100%",
                  height: isMobile ? "auto" : 380, // Set fixed height for desktop
                  borderRadius: 2,
                  overflow: "hidden",
                  cursor: "pointer",
                  // mx: "auto",
                  // border: "2px solid black",
                }}
              >
                <CardMedia
                  component="img"
                  src={article.imagelink}
                  alt={article.headertext}
                  sx={{
                    height: isMobile ? 120 : 200,
                    width: isMobile ? "40%" : "100%",
                    objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
                  }}
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    padding: 3,
                    flexGrow: 1, // Ensures equal height distribution
                    // backgroundColor: "#f9f9f9",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      // backgroundColor: "#e0f7fa",
                      color: theme.palette.warning.main,
                      borderRadius: 1,
                      px: 0,
                      py: 0.5,
                      fontWeight: "bold",
                      mb: 1,
                      display: "inline-block",
                      textAlign: "left",
                    }}
                  >
                    {article.category}
                  </Typography>
                  <Typography
                    display={"block"}
                    variant="caption" // Changed to h6 for better visibility on desktop
                    sx={{
                      fontWeight: "600",
                      textAlign: "left",
                      fontSize: isMobile ? "1rem" : "1rem", // Responsive font size
                      lineHeight: isMobile ? "1.2" : "1.4", // Adjust line height for readability
                      marginBottom: isMobile ? "8px" : "14px",
                      color: "#333",
                      height: isMobile ? "auto" : "48px", // Fixed height for desktop
                      // overflow: "hidden",
                      // textOverflow: "ellipsis", // Add ellipsis for overflow text
                      // whiteSpace: "nowrap", // Prevent wrapping of the text
                    }}
                  >
                    {article.headertext}
                  </Typography>
                </CardContent>

                {!isMobile && (
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      // px: 3,
                      pb: 2,
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Typography
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                      }}
                    >
                      Read more »
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: "#777",
                      }}
                    >
                      <RemoveRedEyeIcon sx={{ mr: 0.5, color: "#555" }} />
                      {article.clicks}
                    </Box>
                  </CardActions>
                )}
              </Grid>
            </Link>
          </Grid>
        ))}
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

// function ImageSection3() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
// <Grid
//   sm={10}
//   sx={{
//     display: "flex",
//     flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//     justifyContent: "space-around",
//     height: isMobile ? "auto" : 400, // Fixed height for desktop view
//     width: isMobile ? "auto" : "auto",
//     boxShadow: isMobile ? 0 : 0,
//     padding: isMobile ? 0 : 1, // Adjust padding for mobile
// transition: isMobile ? "none" : "transform 0.3s",
// "&:hover": {
//   transform: "scale(1.03)",
// },
//   }}
// >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection5() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection6() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection7() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection8() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>
//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection9() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();
//   console.log(slug);

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={4}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={6}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Card
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "space-between",
//               height: "100%", // Ensures all cards have the same height
//               boxShadow: 3, // Adds a little shadow to give the cards depth
//               transition: "transform 0.3s", // Smooth hover effect
//               "&:hover": {
//                 transform: "scale(1.03)", // Slight zoom on hover
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               alt={article.headertext}
//               sx={{
//                 height: 200, // Fixed height for all images
//                 objectFit: "initial", // Ensure image "initials the entire space without distortion
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//               }}
//             >
//               <Typography
//                 variant="overline"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: "0.9rem",
//                   textAlign: "left",
//                   marginBottom: "8px",
//                   height: "40px", // Set a fixed height for consistent text layout
//                   overflow: "hidden", // Hide any overflowing text
//                   textOverflow: "ellipsis", // Ellipsis for overflow
//                   whiteSpace: "nowrap", // Keep text on one line
//                   fontWeight:"700"
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 sx={{
//                   color: "#333",
//                   height: "60px",
//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                   whiteSpace: "normal",
//                   textAlign: "left",
//                   display: "-webkit-box",
//                   WebkitLineClamp: 2, // Show only two lines of text
//                   WebkitBoxOrient: "vertical",
//                 }}
//               >
//                 {article.shortdescription}
//               </Typography>

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   marginTop: "12px",
//                 }}
//               >
//                 <Typography
//                   variant="body2"
//                   sx={{
//                     display: "flex",
//                     alignItems: "center",
//                     textAlign: "left",
//                     color: "#555",
//                   }}
//                 >
//                   <RemoveRedEyeIcon
//                     sx={{
//                       marginRight: 0.5,
//                       color: "grey.700", // Lighter color for the icon
//                       opacity: 0.7, // Slight opacity to tone down the icon
//                     }}
//                   />
//                   {article.clicks}
//                 </Typography>
//                 <Link
//                   href="#"
//                   variant="body2"
//                   sx={{
//                     textDecoration: "none",
//                     color: "primary.main",
//                     "&:hover": { textDecoration: "underline" },
//                   }}
//                 >
//                   Read more »
//                 </Link>
//               </Grid>
//             </CardContent>
//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection9() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={4}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Card
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "50%" : "100%",
//               boxShadow: 1,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               alt={article.headertext}
//               sx={{
//                 height: isMobile ? 200 : 200, // Adjust height for mobile
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: "fill",
//                 borderRadius: "2px", // Make the image look more appealing
//               }}
//             />
//             {/* <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-around",

//                 width: isMobile ? "70%" : "100%", // For mobile, the text takes 60% width
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: "0.85rem", // Slightly larger text for better readability
//                   textAlign: "left",
//                   marginBottom: "4px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               <Typography
//                 variant="caption"
//                 sx={{
//                   color: "#333",
//                   textAlign: "left",
//                   display: "-webkit-box",
//                   WebkitLineClamp: 3, // Show up to 3 lines of text
//                   WebkitBoxOrient: "vertical",
//                   overflow: "hidden",
//                   marginBottom: isMobile ? "4px" : "16px", // Adjust margin for mobile
//                 }}
//               >
//                 {article.shortdescription}
//               </Typography>

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     href="#"
//                     variant="body"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent> */}

// <CardContent
//   sx={{
//     flexGrow: 1,
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between", // Adjust spacing to be more consistent
//     width: '100%', // Ensure it takes full width of the card
//     padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//     boxSizing: 'border-box', // Ensure padding is included in the width
//     height: "auto"
//   }}
// >
//   <Typography
//     variant="body2" // Use h6 for better visibility
//     display="block"
//     gutterBottom
//     sx={{
//       color: "black",
//       fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//       textAlign: "left",
//       marginBottom: isMobile ? "8px" : "14px", // Margin adjustment for readability
//       height: "auto",
//       fontWeight: "700",

//     }}
//   >
//     {article.headertext}
//   </Typography>

//   <Typography
//     variant="caption" // Better suited for descriptions
//     sx={{
//       color: "#333",
//       textAlign: "left",
//       display: "-webkit-box",
//       WebkitLineClamp: 3, // Limit to 3 lines
//       WebkitBoxOrient: "vertical",
//       overflow: "hidden",
//       marginBottom: isMobile ? "8px" : "16px", // Adjust margin for mobile
//     }}
//   >
//     {article.shortdescription}
//   </Typography>

//   <Grid
//     container
//     sx={{
//       display: "flex",
//       justifyContent: "space-between",
//       alignItems: "center",
//       mt: isMobile ? 1 : 2, // Add top margin to space content
//     }}
//   >
//     {!isMobile && ( // Only show eye icon in desktop view
//       <Typography
//         variant="body2"
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           textAlign: "left",
//           color: "#555",
//         }}
//       >
//         <RemoveRedEyeIcon
//           sx={{
//             marginRight: 0.5,
//             color: "grey.700",
//             opacity: 0.7,
//           }}
//         />
//         {article.clicks}
//       </Typography>
//     )}
//     {!isMobile && ( // Only show Read more link in desktop view
//       <Typography
//         component="a" // Use `component="a"` for link styling
//         href="#"
//         variant="body2"
//         sx={{
//           textDecoration: "none",
//           color: "primary.main",
//           "&:hover": { textDecoration: "underline" },
//         }}
//       >
//         Read more »
//       </Typography>
//     )}
//   </Grid>
// </CardContent>

//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

// function ImageSection9() {
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screen
//   const [article, setArticle] = useState([]);
//   const { slug } = useParams();

//   useEffect(() => {
//     axios
//       .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
//       .then((response) => {
//         const articlesArray = response.data.slice(0, 3);
//         setArticle(articlesArray);
//       })
//       .catch((error) => {
//         console.error("There was an error fetching the articles!", error);
//       });
//   }, []);

//   const handleClick = (slug) => {
//     if (slug) {
//       navigate(`/seo/${slug}`);
//     }
//   };

//   return (
//     <Grid
//       container
//       spacing={2}
//       justifyContent="center"
//       alignItems="flex-start"
//       paddingTop={2}
//     >
//       {article.map((article, index) => (
//         <Grid
//           item
//           xs={12}
//           sm={12}
//           md={4}
//           key={index}
//           sx={{ position: "relative", cursor: "pointer" }}
//           onClick={() => handleClick(article.slug)}
//         >
//           <Grid
//             sm={10}
//             sx={{
//               display: "flex",
//               flexDirection: isMobile ? "row" : "column", // Change direction based on mobile view
//               justifyContent: "space-around",
//               height: isMobile ? "auto" : 400, // Fixed height for desktop view
//               width: isMobile ? "auto" : "auto",
//               boxShadow: isMobile ? 0 : 0,
//               padding: isMobile ? 0 : 1, // Adjust padding for mobile
//               transition: isMobile ? "none" : "transform 0.3s",
//               "&:hover": {
//                 transform: "scale(1.03)",
//               },
//             }}
//           >
//             <CardMedia
//               component="img"
//               image={article.imagelink}
//               // alt={article.headertext}

//               sx={{
//                 height: isMobile ? 100 : 200, // Fixed height for images
//                 width: isMobile ? "40%" : "100%", // On mobile, make the image 40% of the card width
//                 objectFit: isMobile ? "contain" : "fill", // Ensure image covers the area
//                 borderRadius: "2px",
//                 // border:"2px solid black"
//               }}
//             />
//             <CardContent
//               sx={{
//                 flexGrow: 1,
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 width: "100%", // Ensure it takes full width of the card
//                 padding: isMobile ? 1 : 2, // Add padding to prevent content from touching edges
//                 boxSizing: "border-box",
//                 height: isMobile ? "auto" : "100%", // Adjust height for desktop view
//                 // border:"2px solid black"
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "red",
//                   fontSize: isMobile ? "0.74rem" : "0.74rem", // Responsive font size
//                   textAlign: "left",
//                   // marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "400",
//                 }}
//               >
//                 {article.category}
//               </Typography>

//               <Typography
//                 variant="body2"
//                 display="block"
//                 gutterBottom
//                 sx={{
//                   color: "black",
//                   fontSize: isMobile ? "1rem" : "1.2rem", // Responsive font size
//                   textAlign: "left",
//                   marginBottom: isMobile ? "8px" : "14px",
//                   height: "auto",
//                   fontWeight: "700",
//                 }}
//               >
//                 {article.headertext}
//               </Typography>

//               {/* {!isMobile && (
//       <Typography
//         variant="caption"
//         sx={{
//           color: "#333",
//           textAlign: "left",
//           display: "-webkit-box",
//           WebkitLineClamp: 3, // Limit to 3 lines
//           WebkitBoxOrient: "vertical",
//           overflow: "hidden",
//           marginBottom: "16px",
//         }}
//       >
//         {article.shortdescription}
//       </Typography>
//     )} */}

//               <Grid
//                 container
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   mt: isMobile ? 1 : 2,
//                 }}
//               >
//                 {!isMobile && ( // Only show eye icon in desktop view
//                   <Typography
//                     variant="body2"
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       textAlign: "left",
//                       color: "#555",
//                     }}
//                   >
//                     <RemoveRedEyeIcon
//                       sx={{
//                         marginRight: 0.5,
//                         color: "grey.700",
//                         opacity: 0.7,
//                       }}
//                     />
//                     {article.clicks}
//                   </Typography>
//                 )}
//                 {!isMobile && ( // Only show Read more link in desktop view
//                   <Typography
//                     component="a"
//                     href="#"
//                     variant="body2"
//                     sx={{
//                       textDecoration: "none",
//                       color: "primary.main",
//                       "&:hover": { textDecoration: "underline" },
//                     }}
//                   >
//                     Read more »
//                   </Typography>
//                 )}
//               </Grid>
//             </CardContent>
//           </Grid>
//         </Grid>
//       ))}
//     </Grid>
//   );
// }

export const New = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [article, setArticle] = useState([]);
  const [categories, setCategories] = useState([]);
  const { slug } = useParams();
  console.log(slug);
  const [MostClickedArticle, setMostClickedArticle] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/api/cards/getarticles`)
      .then((response) => {
        const articlesArray = response.data.slice(0, 1);

        console.log(articlesArray);
        setArticle(articlesArray);

        const articlesSixArray = response.data;
        const mostClickedArticles = articlesSixArray
          .sort((a, b) => b.clicks - a.clicks) // Sort by clicks in descending order
          .slice(0, 3); // Take the top 3
        console.log("====================================");
        console.log(mostClickedArticles);
        console.log("====================================");
        // Set the state for most clicked articles
        setMostClickedArticle(mostClickedArticles);
      })
      .catch((error) => {
        console.error("There was an error fetching the articles!", error);
      });
  }, []);

  const handleClick = (slug) => {
    if (slug) {
      navigate(`/seo/${slug}`);
    }
  };
  // const { slug } = useParams();

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/api/getcategories`
      );

      console.log("categoooooooooooooooooooo");
      console.log(response.data);
      console.log("====================================");
      const categoriesArray = response.data.slice(0, 3);
      setCategories(categoriesArray.reverse());
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <MetadescriptionSetterForPage metaDescription={article.metaDescription} />

      <Grid
        // border={"2px solid black"}

        xs={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <NavBar />

        <Grid
          container
          xs={11}
          md={10}
          lg={10}
          mt={isMobile ? 7 : 20}
          mb={4}
          // spacing={1}
          // border={"2px solid black"}
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          p={3}
        >
          <Grid item xs={12} md={2} lg={4}>
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                // flex: 1,
                fontWeight: "bold",
              }}
            />
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <Grid
              sx={{
                backgroundColor: "red",
                color: "black",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: 0,
                display: "flex",
                alignItems: "center",
                flexGrow: 0,
                flexShrink: 0,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "0.1px",
                  mb: -0.9,
                  mt: 0.5,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: "center",
                    color: "white",
                    fontSize: { xs: "0.75rem", sm: "0.95rem" },
                    fontFamily: "Graphik",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  MARKETING INC+ MEMBER EXCLUSIVES
                </Typography>
              </CardContent>
            </Grid>
          </Grid>
          <Grid item xs={12} md={2} lg={4}>
            <Divider
              sx={{
                orientation: "horizontal",
                borderColor: "black",
                // flex: 1,
                fontWeight: "bold",
              }}
            />
          </Grid>

          {/* <Grid container mt={5} spacing={2} justifyContent="center">
          {MostClickedArticle.map((article, index) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              paddingBottom={2}
              key={index}
              onClick={() => handleClick(article.slug)}
            >
              <Link
                href={article.description}
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{
                  textDecoration: "none",
                  display: "block",
                  cursor: "pointer",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: isMobile ? "row":"row",
                    alignItems: "center",
                    boxShadow: 2,
                    transition: isMobile ? "none" : "transform 0.3s",
              "&:hover": {
                transform: !isMobile && "scale(1.03)", // Only apply hover effect on desktop
              },
                    position: "relative",
                    overflow: "hidden",
                    width: "100%",
                    height: 180, // Set a fixed height for the card
                  }}
                >
                  <CardMedia
                    component="img"
                    image={article.imagelink}
                    alt={article.headertext}
                    sx={{
                      height: isMobile ? 100 : 200, // Adjust image height for mobile
                      width: isMobile ? "40%" : "40%", // Set fixed width for mobile
                      objectFit: isMobile ? "contain" : "fill", // Ensure proper fit
                      borderRadius: "2px",
                    }}
                  />
                  <CardContent
                    sx={{
                      flex: 1,
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      textAlign: "left",
                      height: "100%", // Ensure content takes up full height
                      fontWeight: "700",
                      color: "black",
                    }}
                  >
                    <Typography gutterBottom variant="h6" component="div">
                      {article.headertext}
                    </Typography>
                  </CardContent>
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      bottom: 8,
                      right: 8,
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                      borderRadius: 1,
                      padding: "2px 4px",
                    }}
                  >
                    <RemoveRedEyeIcon
                      sx={{
                        marginRight: 0.5,
                        color: "grey.900",
                        opacity: 0.7,
                      }}
                    />
                    {article.clicks}
                  </Typography>
                </Grid>
              </Link>
            </Grid>
          ))}
        </Grid> */}

          <Grid
            container
            mt={isMobile ? 0 : 5}
            spacing={2}
            justifyContent="center"
          >
            {MostClickedArticle.map((article, index) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={4}
                paddingBottom={2}
                key={index}
                onClick={() => handleClick(article.slug)}
              >
                {/* <Typography
                  href={article.description}
                  target="_blank"
                  rel="noopener"
                  underline="none"
                  sx={{
                    textDecoration: "none",
                    display: "block",
                    cursor: "pointer",
                  
                  }}
                > */}
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: isMobile ? "row" : "row",
                      // alignItems: "",
                      boxShadow: isMobile ? "none" : 0, // Remove box shadow for mobile view
                      transition: isMobile ? "none" : "transform 0.3s", // Disable hover effect for mobile
                      "&:hover": {
                        transform: !isMobile && "scale(1.03)", // Only apply hover effect on desktop
                      },
                      position: "relative",
                      overflow: "hidden",
                      // width: "100%",
                      height: 150, // Set a fixed height for the card
                      cursor:"pointer"
                    }}

                  >
                    <CardMedia
                      component="img"
                      image={article.imagelink}
                      alt={article.headertext}
                      sx={{
                        height: isMobile ? 100 : 150, // Adjust image height for mobile
                        width: "40%", // Set fixed width for both mobile and desktop
                        objectFit: isMobile ? "contain" : "fill", // Ensure proper fit
                        borderRadius: "2px",
                      }}
                    />
                    <CardContent
                      sx={{
                        flex: 1,
                        // paddingLeft: 2,
                        // padding:5,
                        paddingTop: isMobile ? 2 : 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        textAlign: "left",
                        height: isMobile ? "auto" : "auto", // Ensure content takes up full height
                        fontWeight: "700",
                        color: "black",
                        // border:'2px solid black'
                        textDecoration:"none",
                        underline:"none"

                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="caption"
                        // component="div"
                        sx={{
                          fontWeight: "600",
                          textAlign: "left",
                          fontSize: "1rem",
                          display:'flex',
                          // alignItems:'flex-start',
                          textDecoration: "none",
                          underline: "none",
                        }}
                      >
                        {article.headertext}
                      </Typography>

                      {!isMobile && (
                        <Typography
                          variant="body2"
                          sx={{
                            position: "absolute",
                            bottom: 8,
                            right: 8,
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.7)",
                            borderRadius: 1,
                            // padding: "2px 4px",
                          }}
                        >
                          <RemoveRedEyeIcon
                            sx={{
                              marginRight: 0.5,
                              color: "grey.900",
                              opacity: 0.7,
                            }}
                          />
                          {article.clicks}
                        </Typography>
                      )}
                    </CardContent>

                    {/* Hide the "eye icon" in mobile view */}
                  </Grid>
                {/* </Typography> */}
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} md={12} lg={12} marginBottom={3}>
            <Linkage />
          </Grid>

          <Grid
            container
            // spacing={7}
            // gap={1}
            mt={-6}
            xs={12}
            md={12}
            lg={12}
            sx={{ backgroundColor: "" }}
          >
            <Grid
              item
              xs={12}
              md={8}
              lg={8}

              // onClick={() => handleClick(article.slug)}
            >
              {article.length > 0 && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  onClick={() => handleClick(article.slug)}
                  sx={{
                    position: "relative",
                    overflow: "hidden",
                    borderRadius: "8px",
                    // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    boxShadow: isMobile ? 0 : 0,
                    padding: isMobile ? 0 : 1, // Adjust padding for mobile
                    transition: isMobile ? "none" : "transform 0.3s",
                    "&:hover": {
                      transform: "scale(1.03)",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontWeight: 800,
                      fontSize: "1.6rem",
                      textDecoration: "none",
                      cursor: "pointer",
                      underline: "none",
                    }}
                    onClick={() => handleClick(article[0].slug)}
                  >
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      component="img"
                      src={article[0].imagelink} // Display image from the 0th index
                      alt={article[0].headertext || "Article Image"}
                      sx={{
                        height: "auto",
                        mb: 0,
                        width: { xs: "100%", md: "95%", lg: "100%" },
                        objectFit: "cover",
                      }}
                    />

                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          mb: 6,
                          textAlign: "center",
                          color: "black",
                          fontWeight: "700",
                        }}
                      >
                        {article[0].headertext}
                      </Typography>
                    </Grid>
                  </Typography>
                </Grid>
              )}

              {/* <Linkage2 /> */}

              <ReadCard />
              {/* <ReadCard2 />
            <ReadCard3 /> */}
            </Grid>
            <Grid item xs={12} md={4} lg={4} paddingLeft={isMobile ? 0 : 4}>
              <BlogPosts />
            </Grid>

            {/* <Grid container xs={12} md={12} lg={12}>
              {categories.map((categories, index) => (
                <Grid item xs={12} md={12} lg={12} key={index}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      fontWeight: "850",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      paddingBottom: "35px",
                      mt: isMobile ? 0 : 5,
                    }}
                  >
                    {categories.categoriesName}

                    <Grid
                      item
                      xs={12}
                      md={7}
                      lg={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider
                        sx={{
                          orientation: "horizontal",
                          borderColor: "#d6dbd8",
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 4,
                        }}
                      />

                      <Link
                        onClick={() =>
                          navigate(`/category/${categories.categoriesName}`)
                        } // Navigate to CategoryPage
                        sx={{
                          paddingLeft: "20px",
                          textAlign: "left",
                          fontSize: "12px", // Reduced text size
                          textDecoration: "none",
                          color: "#000",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                          cursor: "pointer",
                        }}
                      >
                        View more
                      </Link>
                    </Grid>
                  </Typography>

                  <Grid >
                    
                  <ImageSection1 category={categories.categoriesName}/>
                </Grid>
                </Grid>

                
              ))}

              
            </Grid> */}

            <Grid container>
              {categories.map((category, index) => (
                <Grid item xs={12} key={index}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} md={5}>
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: "left",
                          fontWeight: 700,
                          textTransform: "uppercase",
                          fontSize: isMobile ? "18px" : "24px",
                          color: theme.palette.text.primary,
                          mb: isMobile ? 2 : 4,
                          mt: isMobile ? 2 : 6,
                        }}
                      >
                        {category.categoriesName}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider
                        sx={{
                          flexGrow: 1,
                          borderColor: "#d6dbd8",
                          fontWeight: "bold",
                          mr: 2,
                        }}
                      />
                      <Typography
                        onClick={() =>
                          navigate(`/category/${category.categoriesName}`)
                        }
                        underline="hover"
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          textDecoration: "none",
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                          "&:hover": {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      >
                        View more »
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sx={{ mt: 0, mb: 2 }}>
                    <ImageSection1 category={category.categoriesName} />
                  </Grid>
                </Grid>
              ))}
            </Grid>

            {/* <Grid item xs={12} md={12} lg={12}>
              {article.map((article, index) => (
                <Grid item xs={12} md={12} lg={12} key={index+1}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      fontWeight: "850",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      paddingBottom: "35px",
                      mt: isMobile ? 0 : 5,
                    }}
                  >
                    {article.category}

                    <Grid
                      item
                      xs={12}
                      md={7}
                      lg={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider
                        sx={{
                          orientation: "horizontal",
                          borderColor: "#d6dbd8",
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 4,
                        }}
                      />

                      <Link
                        onClick={() =>
                          navigate(`/category/${article.category}`)
                        } // Navigate to CategoryPage
                        sx={{
                          paddingLeft: "20px",
                          textAlign: "left",
                          fontSize: "12px", // Reduced text size
                          textDecoration: "none",
                          color: "#000",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                          cursor: "pointer",
                        }}
                      >
                        View more
                      </Link>
                    </Grid>
                  </Typography>
                </Grid>
              ))}
            </Grid> */}
            {/* <ImageSection3 /> */}
            {/* 
            <Grid item xs={12} md={12} lg={12}>
              {article.map((article, index) => (
                <Grid item xs={12} md={12} lg={12} key={index}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      fontWeight: "850",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      paddingBottom: "35px",
                      mt: isMobile ? 0 : 5,
                    }}
                  >
                    {article.category}

                    <Grid
                      item
                      xs={12}
                      md={7}
                      lg={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider
                        sx={{
                          orientation: "horizontal",
                          borderColor: "#d6dbd8",
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 4,
                        }}
                      />

                      <Link
                        onClick={() =>
                          navigate(`/category/${article.category}`)
                        } // Navigate to CategoryPage
                        sx={{
                          paddingLeft: "20px",
                          textAlign: "left",
                          fontSize: "12px", // Reduced text size
                          textDecoration: "none",
                          color: "#000",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                          cursor: "pointer",
                        }}
                      >
                        View more
                      </Link>
                    </Grid>
                  </Typography>
                </Grid>
              ))}
            </Grid> */}

            {/* <ImageSection5 /> */}

            {/* <Grid item xs={12} md={12} lg={12}>
              {article.map((article, index) => (
                <Grid item xs={12} md={12} lg={12} key={index}>
                  <Typography
                    sx={{
                      textAlign: "left",
                      display: "flex",
                      fontWeight: "850",
                      fontSize: "20px",
                      textTransform: "uppercase",
                      paddingBottom: "35px",
                      mt: isMobile ? 0 : 5,
                    }}
                  >
                    {article.category}

                    <Grid
                      item
                      xs={12}
                      md={7}
                      lg={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Divider
                        sx={{
                          orientation: "horizontal",
                          borderColor: "#d6dbd8",
                          flex: 1,
                          fontWeight: "bold",
                          marginLeft: 4,
                        }}
                      />

                      <Link
                        onClick={() =>
                          navigate(`/category/${article.category}`)
                        } // Navigate to CategoryPage
                        sx={{
                          paddingLeft: "20px",
                          textAlign: "left",
                          fontSize: "12px", // Reduced text size
                          textDecoration: "none",
                          color: "#000",
                          "&:hover": {
                            color: theme.palette.primary.main,
                          },
                          cursor: "pointer",
                        }}
                      >
                        View more
                      </Link>
                    </Grid>
                  </Typography>
                </Grid>
              ))}
            </Grid>
            <ImageSection6 /> */}
            {/* 
              <Grid item xs={12} md={12} lg={12}>
                {article.map((article, index) => (
                  <Grid item xs={12} md={12} lg={12} key={index}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        fontWeight: "850",
                        fontSize: "20px",
                        textTransform: "uppercase",
                        paddingBottom: "35px",
                        mt: isMobile ? 0 : 5,
                      }}
                    >
                      {article.category}

                      <Grid
                        item
                        xs={12}
                        md={7}
                        lg={7}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Divider
                          sx={{
                            orientation: "horizontal",
                            borderColor: "#d6dbd8",
                            flex: 1,
                            fontWeight: "bold",
                            marginLeft: 4,
                          }}
                        />

                        <Link
                          onClick={() =>
                            navigate(`/category/${article.category}`)
                          } // Navigate to CategoryPage
                          sx={{
                            paddingLeft: "20px",
                            textAlign: "left",
                            fontSize: "12px", // Reduced text size
                            textDecoration: "none",
                            color: "#000",
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        >
                          View more
                        </Link>
                      </Grid>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <ImageSection7 />

              <Grid item xs={12} md={12} lg={12}>
                {article.map((article, index) => (
                  <Grid item xs={12} md={12} lg={12} key={index}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        fontWeight: "850",
                        fontSize: "20px",
                        textTransform: "uppercase",
                        paddingBottom: "35px",
                        mt: isMobile ? 0 : 5,
                      }}
                    >
                      {article.category}

                      <Grid
                        item
                        xs={12}
                        md={7}
                        lg={7}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Divider
                          sx={{
                            orientation: "horizontal",
                            borderColor: "#d6dbd8",
                            flex: 1,
                            borderWidth: {
                              xs: "1px", // Thinner divider on smaller screens
                              sm: "1.5px",
                              md: "0.1px", // Thicker divider on medium and larger screens
                            },
                            width: {
                              xs: "60%", // Adjust width for smaller screens
                              sm: "80%", // Slightly wider on small devices
                              md: "100%", // Full width on medium and large screens
                            },
                            marginRight: 2,
                          }}
                        />

                        <Link
                          onClick={() =>
                            navigate(`/category/${article.category}`)
                          } // Navigate to CategoryPage
                          sx={{
                            paddingLeft: "20px",
                            textAlign: "left",
                            fontSize: "12px", // Reduced text size
                            textDecoration: "none",
                            color: "#000",
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        >
                          View more
                        </Link>
                      </Grid>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <ImageSection8 />

              <Grid item xs={12} md={12} lg={12}>
                {article.map((article, index) => (
                  <Grid item xs={12} md={12} lg={12} key={index}>
                    <Typography
                      sx={{
                        textAlign: "left",
                        display: "flex",
                        fontWeight: "850",
                        fontSize: "20px",
                        textTransform: "uppercase",
                        paddingBottom: "35px",
                        mt: isMobile ? 0 : 5,
                      }}
                    >
                      {article.category}

                      <Grid
                        item
                        xs={12}
                        md={7}
                        lg={7}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Divider
                          sx={{
                            orientation: "horizontal",
                            borderColor: "#d6dbd8",
                            flex: 1,
                            fontWeight: "bold",
                            marginLeft: 4,
                          }}
                        />

                        <Link
                          onClick={() =>
                            navigate(`/category/${article.category}`)
                          } // Navigate to CategoryPage
                          sx={{
                            paddingLeft: "20px",
                            textAlign: "left",
                            fontSize: "12px", // Reduced text size
                            textDecoration: "none",
                            color: "#000",
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          }}
                        >
                          View more
                        </Link>
                      </Grid>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              <ImageSection9 /> */}
            <Footer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default New;
