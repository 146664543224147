import { useEffect } from "react";

export function MetadescriptionSetterForPage({ metadescription }) {
  console.log("MetadescriptionSetterForPage", metadescription);

  useEffect(() => {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", metadescription);
  }, [metadescription]);
  return null;
}
