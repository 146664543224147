// import React, { useEffect, useState } from 'react';
// import { Box } from '@mui/system';
// import DrawerHeader from '../adminGlobal/DrawerHeader';
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
// } from "@mui/material";
// import ArticleIcon from '@mui/icons-material/Article';
// import PersonIcon from '@mui/icons-material/Person';
// import CategoryIcon from '@mui/icons-material/Category';
// import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

// const Dashboard = () => {
//   const [articleCount, setArticleCount] = useState(0);
//   const [authorCount, setAuthorCount] = useState(0);

//   // Fetch the total article count from the backend
//   useEffect(() => {

//     console.log("dwdwd", articleCount );

//     const fetchArticleCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/cards/getarticlescount`);
//         const data = await response.json();
//         setArticleCount(data.count);
//       } catch (error) {
//         console.error("Error fetching article count:", error);
//       }
//     };

//     fetchArticleCount();
//   }, );

//   useEffect(() => {

//     console.log("dwdwd", authorCount );

//     const fetchAuthorCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getauthorcount`);
//         const data = await response.json();
//         setAuthorCount(data.count);
//       } catch (error) {
//         console.error("Error fetching article count:", error);
//       }
//     };

//     fetchAuthorCount();
//   }, );
//   const data = [
//     { title: "Total Articles", count: articleCount, icon: <ArticleIcon fontSize="large" /> },
//     { title: "Total Authors", count: authorCount, icon: <PersonIcon fontSize="large" /> },  // Example count
//     { title: "Total Categories", count: 10, icon: <CategoryIcon fontSize="large" /> }, // Example count
//     { title: "Total Subscription", count: 5, icon: <SubscriptionsIcon fontSize="large" /> }, // Example count
//   ];

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//       <DrawerHeader />

//       <Typography variant='h1' sx={{ justifyContent: "center", mb: 4 }} color={"blueviolet"}>
//         Dashboard
//       </Typography>

//       <Grid container spacing={4} justifyContent="center">
//         {data.map((item) => (
//           <Grid item xs={12} sm={6} md={4} key={item.title}>
//             <Card
//               sx={{
//                 ml: 7,
//                 minWidth: 250,
//                 boxShadow: 3,
//                 borderRadius: 2,
//                 backgroundColor: "cyan",
//                 cursor: "pointer",
//                 transition: "transform 0.2s",
//                 '&:hover': {
//                   transform: 'scale(1.05)',
//                   boxShadow: 6,
//                 },
//                 textAlign: 'center', // Center content
//                 padding: 2, // Add padding for aesthetics
//               }}
//             >
//               <CardContent>
//                 <Box sx={{ mb: 2 }}>{item.icon}</Box>
//                 <Typography variant="h5" component="div" color="text.primary">
//                   {item.title}
//                 </Typography>
//                 <Typography variant="h4" color="text.secondary" sx={{ fontWeight: 'bold', mt: 2 }}>
//                   {item.count}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Grid>
//   );
// };

// export default Dashboard;

// import React, { useEffect, useState } from 'react';
// import { Box } from '@mui/system';
// import DrawerHeader from '../adminGlobal/DrawerHeader';
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
// } from "@mui/material";
// import ArticleIcon from '@mui/icons-material/Article';
// import PersonIcon from '@mui/icons-material/Person';
// import CategoryIcon from '@mui/icons-material/Category';
// import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

// const Dashboard = () => {
//   const [articleCount, setArticleCount] = useState(0);
//   const [authorCount, setAuthorCount] = useState(0);
//   const [categoriesCount, setCategoriesCount] = useState(0);
//   const [subscriberCount, setSubscriberCount] = useState(0);

//   // Fetch article count from backend
//   useEffect(() => {
//     const fetchArticleCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/cards/getarticlescount`);
//         const data = await response.json();
//         setArticleCount(data.count);
//       } catch (error) {
//         console.error("Error fetching article count:", error);
//       }
//     };

//     fetchArticleCount();
//   }, []);

//   // Fetch author count from backend
//   useEffect(() => {
//     const fetchAuthorCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getauthorcount`);
//         const data = await response.json();
//         setAuthorCount(data.count);
//       } catch (error) {
//         console.error("Error fetching author count:", error);
//       }
//     };

//     fetchAuthorCount();
//   }, []);

//   useEffect(() => {
//     const fetchCategoriesCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getcategoriescount`);
//         const data = await response.json();
//         setCategoriesCount(data.count);
//       } catch (error) {
//         console.error("Error fetching categories count:", error);
//       }
//     };

//     fetchCategoriesCount();
//   }, []);

//   useEffect(() => {
//     const fetchSubscriberCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getsubscribercount`);
//         const data = await response.json();
//         setSubscriberCount(data.count);
//       } catch (error) {
//         console.error("Error fetching subscriberC count:", error);
//       }
//     };

//     fetchSubscriberCount();
//   }, []);

//   const data = [
//     { title: "Total Articles", count: articleCount, icon: <ArticleIcon fontSize="large" /> },
//     { title: "Total Authors", count: authorCount, icon: <PersonIcon fontSize="large" /> },
//     { title: "Total Categories", count: categoriesCount, icon: <CategoryIcon fontSize="large" /> }, // Example static count
//     { title: "Total Subscriptions", count: subscriberCount, icon: <SubscriptionsIcon fontSize="large" /> }, // Example static count
//   ];

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//       <DrawerHeader />

//       <Typography variant='h1' sx={{ justifyContent: "center", mb: 4 }} color={"blueviolet"}>
//         Dashboard
//       </Typography>

//       <Grid container spacing={4} justifyContent="center">
//         {data.map((item) => (
//           <Grid item xs={12} sm={6} md={4} key={item.title}>
//             <Card
//               sx={{
//                 ml: 7,
//                 minWidth: 250,
//                 boxShadow: 3,
//                 borderRadius: 2,
//                 backgroundColor: "cyan",
//                 cursor: "pointer",
//                 transition: "transform 0.2s",
//                 '&:hover': {
//                   transform: 'scale(1.05)',
//                   boxShadow: 6,
//                 },
//                 textAlign: 'center', // Center content
//                 padding: 2, // Add padding for aesthetics
//               }}
//             >
//               <CardContent>
//                 <Box sx={{ mb: 2 }}>{item.icon}</Box>
//                 <Typography variant="h5" component="div" color="text.primary">
//                   {item.title}
//                 </Typography>
//                 <Typography variant="h4" color="text.secondary" sx={{ fontWeight: 'bold', mt: 2 }}>
//                   {item.count}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Grid>
//   );
// };

// export default Dashboard;

// import React, { useEffect, useState } from 'react';
// import { Box } from '@mui/system';
// import DrawerHeader from '../adminGlobal/DrawerHeader';
// import {
//   Grid,
//   Card,
//   CardContent,
//   Typography,
// } from "@mui/material";
// import ArticleIcon from '@mui/icons-material/Article';
// import PersonIcon from '@mui/icons-material/Person';
// import CategoryIcon from '@mui/icons-material/Category';
// import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate

// const Dashboard = () => {
//   const [articleCount, setArticleCount] = useState(0);
//   const [authorCount, setAuthorCount] = useState(0);
//   const [categoriesCount, setCategoriesCount] = useState(0);
//   const [subscriberCount, setSubscriberCount] = useState(0);

//   const navigate = useNavigate(); // Initialize navigate function

//   // Fetch article count from backend
//   useEffect(() => {
//     const fetchArticleCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/cards/getarticlescount`);
//         const data = await response.json();
//         setArticleCount(data.count);
//       } catch (error) {
//         console.error("Error fetching article count:", error);
//       }
//     };
//     fetchArticleCount();
//   }, []);

//   // Fetch author count from backend
//   useEffect(() => {
//     const fetchAuthorCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getauthorcount`);
//         const data = await response.json();
//         setAuthorCount(data.count);
//       } catch (error) {
//         console.error("Error fetching author count:", error);
//       }
//     };
//     fetchAuthorCount();
//   }, []);

//   useEffect(() => {
//     const fetchCategoriesCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getcategoriescount`);
//         const data = await response.json();
//         setCategoriesCount(data.count);
//       } catch (error) {
//         console.error("Error fetching categories count:", error);
//       }
//     };
//     fetchCategoriesCount();
//   }, []);

//   useEffect(() => {
//     const fetchSubscriberCount = async () => {
//       try {
//         const response = await fetch(`${process.env.REACT_APP_URL}/api/getsubscribercount`);
//         const data = await response.json();
//         setSubscriberCount(data.count);
//       } catch (error) {
//         console.error("Error fetching subscriber count:", error);
//       }
//     };
//     fetchSubscriberCount();
//   }, []);

//   const data = [
//     { title: "Total Articles", count: articleCount, icon: <ArticleIcon fontSize="large" />, link: '/articlestable' },
//     { title: "Total Authors", count: authorCount, icon: <PersonIcon fontSize="large" />, link: '/addauthor' },
//     { title: "Total Categories", count: categoriesCount, icon: <CategoryIcon fontSize="large" />, link: '/addcategories' },
//     { title: "Total Subscriptions", count: subscriberCount, icon: <SubscriptionsIcon fontSize="large" />, link: '/subscribe' },
//   ];

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent={"center"}>
//       <DrawerHeader />

//       <Typography variant='h1' sx={{ justifyContent: "center", mb: 4 }} color={"blueviolet"}>
//         Dashboard
//       </Typography>

//       <Grid container spacing={4} justifyContent="center">
//         {data.map((item) => (
//           <Grid item xs={12} sm={6}  key={item.title}>
//             <Card
//               sx={{
//                 ml: 7,
//                 minWidth: 250,
//                 boxShadow: 3,
//                 borderRadius: 2,
//                 backgroundColor: "cyan",
//                 cursor: "pointer",
//                 transition: "transform 0.2s",
//                 '&:hover': {
//                   transform: 'scale(1.05)',
//                   boxShadow: 6,
//                 },
//                 textAlign: 'center',
//                 padding: 2,
//               }}
//               onClick={() => navigate(item.link)} // Add onClick to navigate
//             >
//               <CardContent>
//                 <Box sx={{ mb: 2 }}>{item.icon}</Box>
//                 <Typography variant="h5" component="div" color="text.primary">
//                   {item.title}
//                 </Typography>
//                 <Typography variant="h4" color="text.secondary" sx={{ fontWeight: 'bold', mt: 2 }}>
//                   {item.count}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Grid>
//   );
// };

// export default Dashboard;

import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import { Grid, Card, CardContent, Typography, IconButton } from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const Dashboard = () => {
  const [articleCount, setArticleCount] = useState(0);
  const [authorCount, setAuthorCount] = useState(0);
  const [categoriesCount, setCategoriesCount] = useState(0);
  const [subscriberCount, setSubscriberCount] = useState(0);

  const navigate = useNavigate(); // Initialize navigate function

  // Fetch article count from backend
  useEffect(() => {
    const fetchArticleCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/cards/getarticlescount`
        );
        const data = await response.json();
        setArticleCount(data.count);
      } catch (error) {
        console.error("Error fetching article count:", error);
      }
    };
    fetchArticleCount();
  }, []);

  // Fetch author count from backend
  useEffect(() => {
    const fetchAuthorCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getauthorcount`
        );
        const data = await response.json();
        setAuthorCount(data.count);
      } catch (error) {
        console.error("Error fetching author count:", error);
      }
    };
    fetchAuthorCount();
  }, []);

  // Fetch categories count from backend
  useEffect(() => {
    const fetchCategoriesCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getcategoriescount`
        );
        const data = await response.json();
        setCategoriesCount(data.count);
      } catch (error) {
        console.error("Error fetching categories count:", error);
      }
    };
    fetchCategoriesCount();
  }, []);

  // Fetch subscriber count from backend
  useEffect(() => {
    const fetchSubscriberCount = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL}/api/getsubscribercount`
        );
        const data = await response.json();
        setSubscriberCount(data.count);
      } catch (error) {
        console.error("Error fetching subscriber count:", error);
      }
    };
    fetchSubscriberCount();
  }, []);

  const data = [
    {
      title: "Total Articles",
      count: articleCount,
      icon: <ArticleIcon fontSize="large" />,
      link: "/articlestable",
      color: "#2196F3",
    },
    {
      title: "Total Authors",
      count: authorCount,
      icon: <PersonIcon fontSize="large" />,
      link: "/addauthor",
      color: "#4CAF50",
    },
    {
      title: "Total Categories",
      count: categoriesCount,
      icon: <CategoryIcon fontSize="large" />,
      link: "/addcategories",
      color: "#FFC107",
    },
    {
      title: "Total Subscriptions",
      count: subscriberCount,
      icon: <SubscriptionsIcon fontSize="large" />,
      link: "/subscribe",
      color: "#F44336",
    },
  ];

  return (
    <Box sx={{ padding: 4 }}>
      <DrawerHeader />
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          fontWeight: "bold",
          textAlign: "center",
          color: "blueviolet",
        }}
      >
        Dashboard
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {data.map((item) => (
          <Grid item xs={12} sm={6} md={5} key={item.title}>
            <Card
              sx={{
                backgroundColor: "#fff",
                borderRadius: 4,
                boxShadow: 3,
                padding: 2,
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: 6,
                  backgroundColor: "#d8f0f0", // Apply light blue background color on hover
                },
                cursor: "pointer",
                
              }}
              onClick={() => navigate(item.link)}
            >
              <CardContent sx={{ textAlign: "center" }}>
                <IconButton
                  sx={{
                    backgroundColor: item.color,
                    color: "#fff",
                    mb: 2,
                    "&:hover": { backgroundColor: item.color },
                    fontSize: 40,
                    padding: 2,
                  }}
                >
                  {item.icon}
                </IconButton>
                <Typography variant="h6" color="textPrimary" gutterBottom>
                  {item.title}
                </Typography>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  sx={{ fontWeight: "bold" }}
                >
                  {item.count}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
