import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  Modal,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import DrawerHeader from "../adminGlobal/DrawerHeader";
import {
  handleEditorImageDelete,
  handleEditorImageUpload,
} from "../../utils/imageUpload";
import CloseIcon from "@mui/icons-material/Close";

const AddArticleForm = () => {
  const [input, setInputs] = useState({
    category: "",
    author: "",
    headertext: "",
    metadescription: "",
    shortdescription: "",
    imagelink: null,
    body: "",
  });
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [successDateTime, setSuccessDateTime] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "imagelink" && files.length > 0) {
      const selectedFile = files[0];
      if (!selectedFile.type.startsWith("image/")) {
        alert("Please select an image file.");
        return;
      }
      if (selectedFile.size > 3 * 1024 * 1024) {
        // Check if the file size is more than 3MB
        setSnackbarMessage("Uploaded image is more than 3MB");
        setSnackbarSeverity("warning");
        setSnackbarOpen(true);
        return;
      }
      setInputs((prevState) => ({
        ...prevState,
        [name]: selectedFile,
      }));
    } else {
      setInputs((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    Object.entries(input).forEach(([key, value]) => {
      formData.append(key, value);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/cards`,
        formData
      );
      setInputs({
        headertext: "",
        metadescription: "",
        shortdescription: "",
        imagelink: null,
        category: "",
        author: "",
      });
      if (response.status === 200) {
        // const now = new Date();
        // const options = {
        //   year: 'numeric',
        //   day: '2-digit',
        //   month: '2-digit',
        //   timeZone: 'Asia/Kolkata',
        // };
        // const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(now);
        // setSuccessDateTime(formattedDate);
        setShowSuccessModal(true);
        setSnackbarMessage("Article created successfully"); // Updated message
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else if (response.status === 400) {
        setSnackbarMessage("Article already present");
        setSnackbarSeverity("error");
      } else if (response.status === 500) {
        setSnackbarMessage("Error in creating article");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      setSnackbarMessage("Failed to create the article. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchAuthorsAndCategories = async () => {
    setLoading(true);
    try {
      const [authorsResponse, categoriesResponse] = await Promise.all([
        axios.get(`${process.env.REACT_APP_URL}/api/link`),
        axios.get(`${process.env.REACT_APP_URL}/api/getcategories`),
      ]);
      setAuthors(authorsResponse.data.authors);
      setCategories(categoriesResponse.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAuthorsAndCategories();
  }, []);

  const handleModalClose = () => {
    setShowSuccessModal(false);
    navigate("/articlestable");
  };

  return (
    <Grid container direction="row" mt={12} p={6} justifyContent="center">
      <DrawerHeader />

      {loading ? (
        <Grid container justifyContent="center" alignItems="center" mt={4}>
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <Grid
          container
          maxWidth="md"
          spacing={2}
          sx={{
            marginTop: 4,
            backgroundColor: "#f5f5f5",
            padding: 4,
            borderRadius: 2,
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: "#333", mb: 3 }}
          >
            Create a New Blog
          </Typography>
          <Grid container spacing={2}>
            {/* Form Fields */}
            <Grid item xs={12}>
              <Typography
                variant="h6"
                sx={{
                  color: "#333",
                  backgroundColor: "#e3f2fd",
                  padding: 2,
                  borderRadius: 1,
                  textAlign: "center",
                }}
              >
                Enter details to create a Blog
              </Typography>
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Author</InputLabel>
                <Select
                  margin="dense"
                  label="Author"
                  type="author"
                  fullWidth
                  variant="outlined"
                  name="author"
                  value={input.author}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                >
                  {authors
                    .slice()
                    .reverse()
                    .map((auth) => (
                      <MenuItem key={auth.id} value={auth.authorName}>
                        {auth.authorName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  margin="dense"
                  label="Category"
                  type="Category"
                  fullWidth
                  variant="outlined"
                  name="category"
                  value={input.category}
                  onChange={handleChange}
                  sx={{ backgroundColor: "white" }}
                >
                  {categories
                    .slice()
                    .reverse()
                    .map((cat) => (
                      <MenuItem key={cat.id} value={cat.categoriesName}>
                        {cat.categoriesName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid> */}

<Grid item xs={12} sm={6}>
  <FormControl fullWidth>
    <InputLabel>Author</InputLabel>
    <Select
      margin="dense"
      label="Author"
      type="author"
      fullWidth
      variant="outlined"
      name="author"
      value={input.author}
      onChange={handleChange}
      sx={{ backgroundColor: "white" }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200, // Adjust height as needed
            overflowY: 'auto', // Enable vertical scrolling
          },
        },
      }}
    >
      {authors
        .reverse()
        .map((auth) => (
          <MenuItem key={auth.id} value={auth.authorName}>
            {auth.authorName}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
</Grid>

<Grid item xs={12} sm={6}>
  <FormControl fullWidth>
    <InputLabel>Category</InputLabel>
    <Select
      margin="dense"
      label="Category"
      type="Category"
      fullWidth
      variant="outlined"
      name="category"
      value={input.category}
      onChange={handleChange}
      sx={{ backgroundColor: "white" }}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200, // Adjust height as needed
            overflowY: 'auto', // Enable vertical scrolling
          },
        },
      }}
    >
      {categories
        .reverse()
        .map((cat) => (
          <MenuItem key={cat.id} value={cat.categoriesName}>
            {cat.categoriesName}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
</Grid>


            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline // Enables text wrapping
                maxRows={4} // Limits the max number of rows
                label="Header"
                name="headertext"
                value={input.headertext}
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline // Enables text wrapping
                maxRows={4} // Limits the max number of rows
                label="Meta Description"
                name="metadescription"
                value={input.metadescription}
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline // Enables text wrapping
                maxRows={4} // Limits the max number of rows
                label="Short Description"
                name="shortdescription"
                value={input.shortdescription}
                onChange={handleChange}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="imagelink"
                type="file"
                variant="outlined"
                onChange={handleChange}
              />
              {input.imagelink && (
                <img
                  src={URL.createObjectURL(input.imagelink)}
                  alt="Preview"
                  style={{ height: 100, marginTop: 10 }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {/* <Editor
                apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
                initialValue={input.body}
                init={{
                  height: 500,
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
                  toolbar:
                    "undo redo | formatselect | bold italic | forecolor backcolor emoticons | link image | alignleft aligncenter alignright alignjustify | numlist bullist",
                  images_upload_handler: handleEditorImageUpload,
                }}
                onEditorChange={(content) =>
                  setInputs((prev) => ({ ...prev, body: content }))
                }
              /> */}

              <Editor
                apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
                value={input.body}
                init={{
                  height: 500,
                  selector: "textarea",
                  plugins:
                    "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount accordion",
                  toolbar:
                    "undo redo | styles | formatselect | bold italic backcolor | code | link | image | alignleft aligncenter alignright alignjustify | numlist bullist | print",
                  menu: {
                    favs: {
                      title: "My Favorites",
                      items: "code visualaid | searchreplace | emoticons",
                    },
                  },
                  menubar:
                    "favs file edit view insert lists format tools table help",
                  image_uploadtab: true,
                  image_advtab: true,
                  setup: (editor) => {
                    editor.on("init", () => {
                      const removeDeleteButton = (image) => {
                        if (image && image.parentElement) {
                          const button =
                            image.parentElement.querySelector(
                              ".delete-image-btn"
                            );
                          if (button) {
                            button.remove();
                          }
                        }
                      };
                      editor.getDoc().addEventListener("click", (event) => {
                        // Check if the clicked element is an image
                        if (event.target && event.target.tagName === "IMG") {
                          const image = event.target;
                          // Remove existing delete button before adding a new one
                          removeDeleteButton(image);
                          const existingButton =
                            image.parentElement?.querySelector(
                              ".delete-image-btn"
                            );
                          if (!existingButton) {
                            const button = document.createElement("button");
                            button.className = "delete-image-btn";
                            button.innerText = "Delete";
                            button.style.position = "absolute";
                            button.style.top = "0";
                            button.style.left = "0";
                            button.style.backgroundColor = "#D32F2F";
                            button.style.color = "white";
                            button.style.border = "none";
                            button.style.borderRadius = "8px";
                            button.style.padding = "8px 16px";
                            button.style.cursor = "pointer";
                            button.style.zIndex = "1000";
                            button.style.boxShadow =
                              "0px 4px 8px rgba(0, 0, 0, 0.2)";
                            // Add hover effect
                            button.addEventListener("mouseover", () => {
                              button.style.backgroundColor = "#B71C1C";
                            });
                            button.addEventListener("mouseout", () => {
                              button.style.backgroundColor = "#D32F2F";
                            });
                            // Ensure the parent element has relative positioning
                            image.parentElement.style.position = "relative";
                            image.parentElement.appendChild(button);
                            button.addEventListener("click", async () => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this image?"
                                )
                              ) {
                                try {
                                  // Remove the image
                                  editor.dom.remove(image);
                                  // Call your delete handler
                                  await handleEditorImageDelete(image.src);
                                  // Remove the button after the image is deleted
                                  removeDeleteButton(image);
                                } catch (error) {
                                  console.error("Error deleting image:", error);
                                }
                              }
                            });
                          }
                        } else if (
                          event.target &&
                          event.target.tagName !== "IMG"
                        ) {
                          // If it's not an image, attempt to remove the delete button
                          removeDeleteButton(event.target);
                        }
                      });
                    });
                  },
                  images_upload_handler: handleEditorImageUpload,
                }}
                onEditorChange={(content) =>
                  setInputs((prev) => ({ ...prev, body: content }))
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} justifyContent="flex-end" mt={4}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#004080",
                  "&:hover": { backgroundColor: "#003366" },
                }}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate("/articlestable")}
                sx={{
                  borderColor: "#004080",
                  color: "#004080",
                  "&:hover": { borderColor: "#003366", color: "#003366" },
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
    message={
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>{snackbarMessage}</span>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setSnackbarOpen(false)} // Cancel action
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    }
    sx={{
      backgroundColor:
        snackbarSeverity === "success"
          ? "green"
          : snackbarSeverity === "error"
          ? "red"
          : "orange",
      color: "white",
      fontSize: "1rem",
      borderRadius: 1,
      padding: 1,
    }}
  />
</Snackbar>
      {/* <Modal
        open={showSuccessModal}
        onClose={handleModalClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "90%" : "50%",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleModalClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: "text.primary",
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography id="modal-title" variant="h6" component="h2">
            Success
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            The article was successfully created on {successDateTime}.
          </Typography>
        </Box>
      </Modal> */}
    </Grid>
  );
};

export default AddArticleForm;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   TextField,
//   Button,
//   MenuItem,
//   Typography,
//   Select,
//   FormControl,
//   InputLabel,
//   CircularProgress,
//   Snackbar,
//   SnackbarContent,
//   Modal,
//   Box,
//   IconButton,
//   useTheme,
//   useMediaQuery,
// } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Editor } from "@tinymce/tinymce-react";
// import DrawerHeader from "../adminGlobal/DrawerHeader";
// import { handleEditorImageUpload } from "../../utils/imageUpload";
// import CloseIcon from "@mui/icons-material/Close";

// const AddArticleForm = () => {
//   const [input, setInputs] = useState({
//     category: "",
//     author: "",
//     headertext: "",
//     metadescription: "",
//     shortdescription: "",
//     imagelink: null,
//     body: "",
//   });
//   const [authors, setAuthors] = useState([]);
//   const [categories, setCategories] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [successDateTime, setSuccessDateTime] = useState("");
//   const [showSuccessModal, setShowSuccessModal] = useState(false);
//   const navigate = useNavigate();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   const handleChange = (e) => {
//     const { name, value, files } = e.target;
//     if (name === "imagelink" && files.length > 0) {
//       const selectedFile = files[0];
//       if (!selectedFile.type.startsWith("image/")) {
//         alert("Please select an image file.");
//         return;
//       }
//       if (selectedFile.size > 3 * 1024 * 1024) { // Check if the file size is more than 3MB
//         setSnackbarMessage("Uploaded image is more than 3MB");
//         setSnackbarSeverity("warning");
//         setSnackbarOpen(true);
//         return;
//       }
//       setInputs((prevState) => ({
//         ...prevState,
//         [name]: selectedFile,
//       }));
//     } else {
//       setInputs((prevState) => ({
//         ...prevState,
//         [name]: value,
//       }));
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const formData = new FormData();
//     Object.entries(input).forEach(([key, value]) => {
//       formData.append(key, value);
//     });

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_URL}/api/cards`,
//         formData
//       );
//       setInputs({
//         headertext: "",
//         metadescription: "",
//         shortdescription: "",
//         imagelink: null,
//         category: "",
//         author: "",
//       });
//       if (response.status === 201) {
//         const now = new Date();
//         const options = {
//           year: 'numeric',
//           day: '2-digit',
//           month: '2-digit',
//           timeZone: 'Asia/Kolkata',
//         };
//         const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(now);
//         setSuccessDateTime(formattedDate);
//         setShowSuccessModal(true);
//         setSnackbarMessage("Success");
//         setSnackbarSeverity("success");
//         setSnackbarOpen(true);
//       } else if (response.status === 400) {
//         setSnackbarMessage("Article already present");
//         setSnackbarSeverity("error");
//       } else if (response.status === 500) {
//         setSnackbarMessage("Error in creating article");
//         setSnackbarSeverity("error");
//       }
//     } catch (error) {
//       setSnackbarMessage("Failed to create the article. Please try again.");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchAuthorsAndCategories = async () => {
//     setLoading(true);
//     try {
//       const [authorsResponse, categoriesResponse] = await Promise.all([
//         axios.get(`${process.env.REACT_APP_URL}/api/link`),
//         axios.get(`${process.env.REACT_APP_URL}/api/getcategories`),
//       ]);
//       setAuthors(authorsResponse.data.authors);
//       setCategories(categoriesResponse.data);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchAuthorsAndCategories();
//   }, []);

//   const handleModalClose = () => {
//     setShowSuccessModal(false);
//     navigate("/articlestable");
//   };

//   return (
//     <Grid container direction="row" mt={12} p={6} justifyContent="center">
//       <DrawerHeader />

//       {loading ? (
//         <Grid container justifyContent="center" alignItems="center" mt={4}>
//           <CircularProgress color="primary" />
//         </Grid>
//       ) : (
//         <Grid container maxWidth="md" spacing={2} sx={{ marginTop: 4, backgroundColor: "#f5f5f5", padding: 4, borderRadius: 2, boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
//           <Typography variant="h4" align="center" gutterBottom sx={{ color: "#333", mb: 3 }}>
//             Create a New Blog
//           </Typography>
//           <Grid container spacing={2}>
//             {/* Form Fields */}
//             <Grid item xs={12}>
//               <Typography variant="h6" sx={{ color: "#333", backgroundColor: "#e3f2fd", padding: 2, borderRadius: 1, textAlign: "center" }}>
//                 Enter details to create a Blog
//               </Typography>
//             </Grid>

//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>Author</InputLabel>
//                 <Select
//                   name="author"
//                   value={input.author}
//                   onChange={handleChange}
//                   sx={{ backgroundColor: "white" }}
//                 >
//                   {authors.slice().reverse().map((auth) => (
//                     <MenuItem key={auth.id} value={auth.authorName}>
//                       {auth.authorName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel>Category</InputLabel>
//                 <Select
//                   name="category"
//                   value={input.category}
//                   onChange={handleChange}
//                   sx={{ backgroundColor: "white" }}
//                 >
//                   {categories.slice().reverse().map((cat) => (
//                     <MenuItem key={cat.id} value={cat.categoriesName}>
//                       {cat.categoriesName}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>

//             <Grid item xs={12}>
//               <TextField fullWidth label="Header" name="headertext" value={input.headertext} onChange={handleChange} sx={{ backgroundColor: "white" }} />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Meta Description" name="metadescription" value={input.metadescription} onChange={handleChange} sx={{ backgroundColor: "white" }} />
//             </Grid>
//             <Grid item xs={12}>
//               <TextField fullWidth label="Short Description" name="shortdescription" value={input.shortdescription} onChange={handleChange} sx={{ backgroundColor: "white" }} />
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <TextField name="imagelink" type="file" variant="outlined" onChange={handleChange} />
//               {input.imagelink && <img src={URL.createObjectURL(input.imagelink)} alt="Preview" style={{ height: 100, marginTop: 10 }} />}
//             </Grid>
//             <Grid item xs={12}>
//               <Editor
//                 apiKey="7obrevvpz409sm0uk6qluafdw0gxyl34mdy1qft094fhg1pe"
//                 initialValue={input.body}
//                 init={{
//                   height: 500,
//                   plugins: "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code wordcount",
//                   toolbar: "undo redo | formatselect | bold italic | forecolor backcolor emoticons | link image | alignleft aligncenter alignright alignjustify | numlist bullist",
//                   images_upload_handler: handleEditorImageUpload,
//                 }}
//                 onEditorChange={(content) => setInputs((prev) => ({ ...prev, body: content }))}
//               />
//             </Grid>
//           </Grid>
//           <Grid container spacing={2} justifyContent="flex-end" mt={4}>
//             <Grid item>
//               <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ backgroundColor: "#004080", "&:hover": { backgroundColor: "#003366" } }}>
//                 Submit
//               </Button>
//             </Grid>
//           </Grid>
//         </Grid>
//       )}

//       <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
//         <SnackbarContent
//           message={snackbarMessage}
//           action={
//             <IconButton size="small" color="inherit" onClick={() => setSnackbarOpen(false)}>
//               <CloseIcon />
//             </IconButton>
//           }
//           style={{
//             backgroundColor:
//               snackbarSeverity === "success"
//                 ? "#4caf50"
//                 : snackbarSeverity === "error"
//                 ? "#f44336"
//                 : "#ff9800",
//             color: "#fff",
//           }}
//         />
//       </Snackbar>

//       <Modal
//         open={showSuccessModal}
//         onClose={handleModalClose}
//         aria-labelledby="modal-title"
//         aria-describedby="modal-description"
//       >
//         <Box sx={{ ...style, width: isMobile ? '80%' : '400px' }}>
//           <Typography variant="h6" id="modal-title">
//             Success
//           </Typography>
//           <Typography variant="body1" id="modal-description" sx={{ marginTop: 2 }}>
//             The article was created successfully on {successDateTime}.
//           </Typography>
//           <Button
//             onClick={handleModalClose}
//             sx={{ marginTop: 2, backgroundColor: "#004080", "&:hover": { backgroundColor: "#003366" } }}
//           >
//             Close
//           </Button>
//         </Box>
//       </Modal>
//     </Grid>
//   );
// };

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
// };

// export default AddArticleForm;
