import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import NavBar from "../global/header";
import {
  Button,
  CardMedia,
  Link,
  List,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MobileMarketingImg from "../main/img/mobile-marketing.jpg";
import SocialMediaMarketingImg from "../main/img/social-media-marketing.jpg";
import ContentMarketingImg from "../main/img/content-marketing.jpg";
import BuisnessImg from "../main/img/Buisness.jpg";
import DigitalImg from "../main/img/Digital.png";
import EmailMarketingImg from "../main/img/email-marketing.jpg";
import Footer from "../global/footer";

const articles = [
  {
    img: MobileMarketingImg,
    tag: "MOBILE MARKETING",
    title: "The Impact of 5G Technology on Mobile Marketing Strategies",
    date: "July 3, 2024",
    description: "The advent of 5G technology is set to revolutionize various industries, and ",
  },
  {
    img: SocialMediaMarketingImg,
    tag: "SOCIAL MEDIA MARKETING",
    title: "Creating Engaging Social Media Stories: Tips and Tools",
    date: "July 2, 2024",
    description: "Social media stories have become a dynamic way for brands to connect with their audience.",
  },
  {
    img: ContentMarketingImg,
    tag: "SEO",
    title: "What You Need to Know About Improving Your SEO Ranking",
    date: "July 1, 2024",
    description: "Improving Your SEO Ranking. In the ever-evolving landscape of digital marketing,",
  },
  {
    img: EmailMarketingImg,
    tag: "Email MARKETING",
    title: "The Impact of 5G Technology on Mobile Marketing Strategies",
    date: "July 3, 2024",
    description: "The advent of 5G technology is set to revolutionize various industries, and ",
  },
  {
    img: BuisnessImg,
    tag: "Business",
    title: "Creating Engaging Social Media Stories: Tips and Tools",
    date: "July 2, 2024",
    description: "Social media stories have become a dynamic way for brands to connect with their audience.",
  },
  {
    img: DigitalImg,
    tag: "Digital Marketing",
    title: "What You Need to Know About Improving Your SEO Ranking",
    date: "July 1, 2024",
    description: "Improving Your SEO Ranking. In the ever-evolving landscape of digital marketing,",
  },
];

function ArticleList({ filteredArticles }) {
  return (
    <List sx={{ width: '100%', maxWidth: 900, margin: '0 auto', mt: 15 }}>
      {filteredArticles.map((article, index) => (
        <ListItem key={index} sx={{ mb: 4 }}>
          <Card sx={{ width: '100%', display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
            <CardMedia
              component="img"
              src={article.img}
              alt={article.title}
              sx={{ width: { xs: '100%', md: 200 }, height: { xs: 190, md: 'auto' } }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Grid sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mb: 1 }}>
                <Typography
                  variant="body2"
                  color="white"
                  sx={{
                    backgroundColor: "primary.light",
                    borderRadius: 1,
                    px: 1,
                    py: 0.5,
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  {/* {article.tag} */}
                </Typography>
              </Grid>
              <Typography variant="h6" sx={{ textAlign: 'left' }} gutterBottom>
                {article.title}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left' }}>
                {article.date}
              </Typography>
              <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'left', mt: 1 }}>
                {article.description}
              </Typography>
              <Link
                href="#"
                variant="body2"
                color="primary"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  mt: 1,
                }}
              >
                Read more <span style={{ marginLeft: 4 }}>»»</span>
              </Link>
            </CardContent>
          </Card>
        </ListItem>
      ))}
    </List>
  );
}

export const Blogs = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredArticles = selectedCategory === "All"
    ? articles
    : articles.filter(article => article.tag.toLowerCase() === selectedCategory.toLowerCase());

  return (
    <Grid
      xs={12}
      md={8}
      lg={8}
      sx={{
        backgroundColor: "#FFFFFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <NavBar />
      <Grid container xs={12} md={10} lg={10} justifyContent="center" spacing={2}>
        {["All", "MOBILE MARKETING", "SOCIAL MEDIA MARKETING", "SEO", "Email MARKETING", "Business", "Digital Marketing"].map((category) => (
          <Button
            key={category}
            variant="contained"
            color={selectedCategory === category ? "primary" : "default"}
            onClick={() => handleCategoryClick(category)}
            sx={{ m: 1 }}
          >
            {category}
          </Button>
        ))}
      </Grid>
      <Grid container xs={12} md={10} lg={10} justifyContent={isMobile ? "center" : "flex-start"}>
        <ArticleList filteredArticles={filteredArticles} />
      </Grid>
      <Grid container xs={12} md={10} lg={10} mt={3} p={isMobile ? 3 : 0}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Blogs;
