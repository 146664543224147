// import React from "react";
// import {
//   Grid,
//   Typography,
//   Button,
//   TextField,
//   Box,
//   Divider,
// } from "@mui/material";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import XIcon from "@mui/icons-material/X";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import { useMediaQuery } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

// function Subscriber () {

//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return(
//   <Grid
//           xs={12}
//           md={4}
//           textAlign={"left"}
//           gap={isMobile ? 3 : 0}
//           marginTop={isMobile ? 3 : 0}
//         >
//           <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//             MARKETING INC+
//           </Typography>
//           <Typography variant="body2" sx={{ marginBottom: 2, paddingTop: 2 }}>
//             Get access to tools and analysis to stay ahead of the trends
//             transforming media and marketing.
//           </Typography>
//           <Button
//             variant="contained"
//             sx={{
//               color: "primary",
//               backgroundColor: "#000000",
//               fontWeight: "bold",
//               "&:hover": {
//                 backgroundColor: "#FFFFFF", // Changes button background to white
//                 color: "#000000", // Changes text color to black
//               },
//             }}
//           >
//             SUBSCRIBE
//           </Button>
//         </Grid>
//         );

// }
// export const Footer = () => {
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

//   return (
//     <Grid
//       sx={{
//         paddingTop: 6,
//         borderTop: "1px solid black",
//         paddingBottom: 5,
//         // border: "2px solid black",
//       }}
//     >
//       <Grid container spacing={0} xs={12} md={12} lg={12}>
//         {/* DIGIDAY+ Section */}

// <Subscriber/>

//         {/* Divider for larger screens */}
//         {!isMobile && (
//           <Divider
//             orientation="vertical"
//             flexItem
//             sx={{ display: { xs: "none", md: "block" }, marginRight: 1 }}
//           />
//         )}

//         {/* NEWSLETTER Section */}
//         <Grid
//           container
//           xs={12}
//           md={4}
//           textAlign={"left"}
//           gap={isMobile ? 2 : 0}
//           marginTop={isMobile ? 4 : 0}
//         >
//           <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//             NEWSLETTER
//           </Typography>
//           <Typography variant="body1" sx={{ marginBottom: 0, paddingTop: 0 }}>
//             Get Marketing's top stories every morning in your email inbox.
//           </Typography>

//           <Grid item xs={12} md={8} lg={8}>
//             <TextField
//               fullWidth
//               label="Business email"
//               variant="standard"
//               size="small"
//               sx={{ marginBottom: 0 }}
//             />
//           </Grid>
//           <Grid item xs={12} md={8} lg={8}>
//             <TextField
//               fullWidth
//               label="Job title"
//               variant="standard"
//               size="small"
//               sx={{ marginBottom: 0 }}
//             />
//           </Grid>

//           <Grid
//             item
//             xs={12}
//             md={4}
//             lg={4}
//             mt={1}
//             paddingLeft={isMobile ? 0 : 2}
//           >
//             <Button
//               variant="contained"
//               sx={{
//                 color: "primary",
//                 backgroundColor: "#000000",
//                 fontWeight: "bold",
//                 "&:hover": {
//                   backgroundColor: "#FFFFFF", // Changes button background to white
//                   color: "#000000", // Changes text color to black
//                 },
//               }}
//             >
//               SIGN UP
//             </Button>
//           </Grid>
//         </Grid>

//         {/* Divider for larger screens */}
//         {!isMobile && (
//           <Divider
//             orientation="vertical"
//             flexItem
//             sx={{ display: { xs: "none", md: "block" }, marginRight: 1 }}
//           />
//         )}

//         {/* CONNECT Section */}
//         <Grid
//           xs={12}
//           md={3}
//           textAlign={"left"}
//           gap={isMobile ? 3 : 0}
//           marginTop={isMobile ? 3 : 0}
//         >
//           <Typography variant="h6" sx={{ fontWeight: "bold" }}>
//             CONNECT
//           </Typography>
//           <Typography variant="body1" sx={{ marginBottom: 2, paddingTop: 2 }}>
//             Follow @Marketing inc for the latest news, insider access to events and
//             more.
//           </Typography>
//           <Grid
//             sx={{ display: "flex", gap: 3, paddingTop: 2, marginBottom: 2 }}
//           >
//             <FacebookIcon sx={{ fontSize: "40px" }} />
//             <InstagramIcon sx={{ fontSize: "40px" }} />
//             <XIcon sx={{ fontSize: "40px" }} />
//             <LinkedInIcon sx={{ fontSize: "40px" }} />
//           </Grid>
//         </Grid>
//       </Grid>

//       <Divider sx={{ marginY: 4 }} />

//       <Grid
//         container
//         justifyContent="space-between"
//         flexWrap="wrap"
//         textAlign="center"
//       >
//         <Grid xs={12} md={6} lg={6} gap={2} item sx={{ display: "flex", marginBottom: 4 }}>
//           <Typography fontSize={isMobile ? 10 : '12px'} variant="body2">FAQ</Typography>
//           <Typography fontSize={isMobile ? 10 : '12px'} variant="body2">ADVERTISE</Typography>
//           <Typography fontSize={isMobile ? 10 : '12px'} variant="body2">PRIVACY POLICY</Typography>
//           <Typography fontSize={isMobile ? 10 : '12px'} variant="body2">MASTHEAD</Typography>
//           <Typography fontSize={isMobile ? 10 : '12px'} variant="body2">MARKETING MEDIA</Typography>
//         </Grid>
//         <Typography variant="body2">© 2024. ALL RIGHTS RESERVED</Typography>
//       </Grid>
//     </Grid>
//   );
// };

// export default Footer;

import React, { useState } from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ReCAPTCHA from "react-google-recaptcha";




function Subscriber() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [responseMessage, setResponseMessage] = useState(""); // State for response message
  const [openSnackbar, setOpenSnackbar] = useState(false); // State for Snackbar
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [capVal, setCapVal] = useState(null); // reCAPTCHA value


  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    setLoading(true); // Show loader
    if (!capVal) {
      setResponseMessage("Please complete the reCAPTCHA verification.");
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/api/addsubscriber`,
        {
          formData: {
            ...formData,
          },
        }
      );

      if (response.status === 201) {
        setResponseMessage("Subscription successful!");
        setOpenSnackbar(true); // Show Snackbar on success
        handleClose();
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setResponseMessage("Email is already subscribed.");
      } else {
        setResponseMessage("Error: Unable to create subscriber.");
      }
      setOpenSnackbar(true); // Show Snackbar with the error message
      setTimeout(() => {
        setResponseMessage("");
        setOpenSnackbar(false); // Optionally hide Snackbar after timeout
      }, 4000);
    
    } finally {
      setLoading(false); // Hide loader
    }
  };

  // State to control the dialog open/close
  const [open, setOpen] = useState(false);

  // Function to open the dialog
  const handleOpen = () => {
    setOpen(true);
  };

  // Function to close the dialog
  const handleClose = () => {
    setOpen(false);
    setFormData({ name: "", email: "" }); // Reset form data
    setCapVal(null); // Reset reCAPTCHA

  };

  const onReCAPTCHAChange = (value) => {
    setCapVal(value);
  };

  return (
    <Grid
      xs={12}
      md={4}
      textAlign={"left"}
      gap={isMobile ? 3 : 0}
      marginTop={isMobile ? 3 : 0}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        MARKETING INC+
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: 2, paddingTop: 2 }}>
        Get access to tools and analysis to stay ahead of the trends
        transforming media and marketing.
      </Typography>
      <Button
        variant="contained"
        sx={{
          color: "primary",
          backgroundColor: "#000000",
          fontWeight: "bold",
          "&:hover": {
            backgroundColor: "#FFFFFF", // Changes button background to white
            color: "#000000", // Changes text color to black
          },
        }}
        onClick={handleOpen} // Opens the dialog
      >
        SUBSCRIBE
      </Button>

      <Dialog open={open} onClose={handleClose}>
  <DialogTitle>Subscribe</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      name="name"
      label="Name"
      type="text"
      fullWidth
      variant="outlined"
      value={formData.name}
      onChange={handleChange}
    />
    <TextField
      margin="dense"
      name="email"
      label="Email Address"
      type="email"
      fullWidth
      variant="outlined"
      value={formData.email}
      onChange={handleChange}
    />

    {responseMessage === "Email is already subscribed." && (
      <Typography color="error" sx={{ marginTop: 1 }}>
        {responseMessage}
      </Typography>
    )}
  </DialogContent>


 
  {/* <DialogActions>


  <ReCAPTCHA
            sitekey="6LdxZU0qAAAAACksyRXScrhWPyxchEXmHMAYf4Ra" // Replace with your site key
            onChange={onReCAPTCHAChange} // Properly set onChange handler
          />

    {responseMessage === "Email is already subscribed." && (
      <Typography
        color="black"
        backgroundColor="red"
        sx={{ marginTop: 1 }}
      >
        {responseMessage}
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            setResponseMessage(""); // Clear the message
            setOpenSnackbar(false); // Hide the Snackbar
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Typography>
    )}
    <Button
      sx={{
        color: "white",
        backgroundColor: "red",
        "&:hover": { textDecoration: "underline", color: "red" },
      }}
      onClick={handleClose}
    >
      Cancel
    </Button>
    <Button
      sx={{
        color: "white",
        backgroundColor: "blue",
        "&:hover": { textDecoration: "underline", color: "green" },
      }}
      onClick={handleSubmit}
      disabled={loading || !capVal}

    >
      {loading ? <CircularProgress size={29} style={{'color': 'white'}} /> : "Subscribe"}
    </Button>
  </DialogActions> */}





<DialogActions sx={{ flexDirection: "column", alignItems: "center", width: "95%" }}>
  {/* reCAPTCHA component */}
  <ReCAPTCHA
  // 6LdxZU0qAAAAACksyRXScrhWPyxchEXmHMAYf4Ra // local key
 
    sitekey="6LcYik0qAAAAAHRcFfO0daoCicudtktYweXek1Kc"
    onChange={onReCAPTCHAChange}
    sx={{ marginBottom: 2, display: 'flex', justifyContent: 'center' }}
  />

  {/* Display error message when email is already subscribed */}
  {responseMessage === "Email is already subscribed." && (
    <Typography
      color="white"
      sx={{
        backgroundColor: "red",
        padding: "10px",
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
        maxWidth: "100%", // Ensure responsiveness
      }}
    >
      {responseMessage}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => {
          setResponseMessage(""); // Clear the message
          setOpenSnackbar(false); // Hide the Snackbar
        }}
        sx={{ marginLeft: 1 }} // Add margin for spacing between text and icon
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Typography>
  )}

  {/* Buttons aligned in one row */}
  <Box sx={{ display: "flex", justifyContent: "space-between", width: isMobile ? "90%" : "60%", paddingX: 2, marginTop:"1" }}>
    {/* Cancel Button */}
    <Button
      sx={{
        color: "white",
        backgroundColor: "red",
        minWidth: "120px", // Ensure a minimum width for the button
        whiteSpace: "nowrap", // Prevent text overflow
        "&:hover": { textDecoration: "underline", color: "red" },
      }}
      onClick={handleClose}
    >
      Cancel
    </Button>

    {/* Subscribe Button */}
    <Button
      sx={{
        color: "white",
        backgroundColor: "blue",
        minWidth: "120px", // Ensure a minimum width for the button
        whiteSpace: "nowrap", // Prevent text overflow
        "&:hover": { textDecoration: "underline", color: "green" },
      }}
      onClick={handleSubmit}
      disabled={loading || !capVal}
    >
      {loading ? (
        <CircularProgress size={24} style={{ color: "white" }} />
      ) : (
        "Subscribe"
      )}
    </Button>
  </Box>
</DialogActions>



 
</Dialog>


      {/* Snackbar for success message */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={9000}
        onClose={() => setOpenSnackbar(false)}
        message={responseMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ "& .MuiSnackbarContent-root": { backgroundColor: "green" } }}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpenSnackbar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Grid>
  );
}

export const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      sx={{
        paddingTop: 6,
        borderTop: "1px solid black",
        paddingBottom: 5,
      }}
    >
      <Grid container spacing={0} xs={12} md={12} lg={12}>
        {/* DIGIDAY+ Section */}
        <Subscriber />

        {/* Divider for larger screens */}
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginRight: 1 }}
          />
        )}

        {/* NEWSLETTER Section */}
        <Grid
          container
          xs={12}
          md={4}
          textAlign={"left"}
          gap={isMobile ? 2 : 0}
          marginTop={isMobile ? 4 : 0}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            NEWSLETTER
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 0, paddingTop: 0 }}>
            Get Marketing's top stories every morning in your email inbox.
          </Typography>

          <Grid item xs={12} md={8} lg={8}>
            <TextField
              fullWidth
              label="Business email"
              variant="standard"
              size="small"
              sx={{ marginBottom: 0 }}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <TextField
              fullWidth
              label="Job title"
              variant="standard"
              size="small"
              sx={{ marginBottom: 0 }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            mt={1}
            paddingLeft={isMobile ? 0 : 2}
          >
            <Button
              variant="contained"
              sx={{
                color: "primary",
                backgroundColor: "#000000",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#FFFFFF", // Changes button background to white
                  color: "#000000", // Changes text color to black
                },
              }}
            >
              SIGN UP
            </Button>
          </Grid>
        </Grid>

        {/* Divider for larger screens */}
        {!isMobile && (
          <Divider
            orientation="vertical"
            flexItem
            sx={{ display: { xs: "none", md: "block" }, marginRight: 1 }}
          />
        )}

        {/* CONNECT Section */}
        <Grid
          xs={12}
          md={3}
          textAlign={"left"}
          gap={isMobile ? 3 : 0}
          marginTop={isMobile ? 3 : 0}
        >
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            CONNECT
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2, paddingTop: 2 }}>
            Follow @Marketing inc for the latest news, insider access to events
            and more.
          </Typography>
          <Grid
            sx={{ display: "flex", gap: 3, paddingTop: 2, marginBottom: 2 }}
          >
            <FacebookIcon sx={{ fontSize: "40px" }} />
            <InstagramIcon sx={{ fontSize: "40px" }} />
            <XIcon sx={{ fontSize: "40px" }} />
            <LinkedInIcon sx={{ fontSize: "40px" }} />
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 4 }} />

      <Grid
        container
        justifyContent="space-between"
        flexWrap="wrap"
        textAlign="center"
      >
        <Grid
          xs={12}
          md={6}
          lg={6}
          gap={2}
          item
          sx={{ display: "flex", marginBottom: 4 }}
        >
          <Typography fontSize={isMobile ? 10 : "12px"} variant="body2">
            FAQ
          </Typography>
          <Typography fontSize={isMobile ? 10 : "12px"} variant="body2">
            ADVERTISE
          </Typography>
          <Typography fontSize={isMobile ? 10 : "12px"} variant="body2">
            PRIVACY POLICY
          </Typography>
          <Typography fontSize={isMobile ? 10 : "12px"} variant="body2">
            MASTHEAD
          </Typography>
          <Typography fontSize={isMobile ? 10 : "12px"} variant="body2">
            MARKETING MEDIA
          </Typography>
        </Grid>
        <Typography variant="body2">© 2024. ALL RIGHTS RESERVED</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
